import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, AccordionSummary } from '@mui/material';
import { Card } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import ReportLine from '../../../interfaces/output/ReportLine';
import LanguageUtils from '../../../utils/LanguageUtils';
import { ensure } from '../../../utils/arrayUtils';

interface IViewReportLineProps {
    classes?: any;
    theme?: Theme;
    reportLine: ReportLine;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Report line details'),
    gtsCustomerNo: LanguageUtils.createMessage('gts customer number'),
    customerName: LanguageUtils.createMessage('Customer name'),
    fromPlace: LanguageUtils.createMessage('From place'),
    toPlace: LanguageUtils.createMessage('To place'),
    amount: LanguageUtils.createMessage('Amount'),
    totalPrice: LanguageUtils.createMessage('Total price')
};

export default function ViewReportLine(props: IViewReportLineProps): JSX.Element {
    const classes = styles();
    const { reportLine } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.customerName} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{reportLine?.report_pipelineGTSCustomerOut?.customerName || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.gtsCustomerNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{reportLine?.report_pipelineGTSCustomerOut?.gtsCustomerNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromPlace} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{reportLine.fromPlace || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toPlace} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{reportLine.toPlace || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.amount} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{reportLine.amount || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.totalPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {reportLine.totalPrice &&
                                            intl.formatNumber(ensure(Math.round(reportLine.totalPrice)), {
                                                maximumFractionDigits: 0
                                            }) || '-'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}