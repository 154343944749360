import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import FactorSetDepartureApi from '../api/factorSetDepartureApi';
import { createFactorSetDepartureSuccess, deactivateFactorSetDepartureSuccess, fetchFactorSetDeparturesSuccess,
    fetchFactorSetDepartureByIdSuccess, updateFactorSetDepartureSuccess, createFactorSetDepartureError,
    deactivateFactorSetDepartureError, fetchFactorSetDepartureByIdError, fetchFactorSetDeparturesError,
    updateFactorSetDepartureError,
    fetchFactorSetDepartureTypesSuccess,
    fetchFactorSetDepartureTypesError,
    createDepartureVersionSuccess,
    createDepartureVersionError }
    from '../reducers/factorSetDepartureReducer';
import DepartureFilters from '../components/factorSets/departure/departureFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetType from '../interfaces/output/factorSetType';

function* doFetchFactorSetDeparture(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDepartureApi.fetchFactorSetDepartureById, factorSetId, versionId);
    
        yield put(fetchFactorSetDepartureByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDepartureByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDepartures(action: PayloadAction<{ paging: Paging, filters: DepartureFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetDepartureApi.fetchFactorSetDepartures, paging, filters);

        yield put(fetchFactorSetDeparturesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDeparturesError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDepartureTypes(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;
    try {
        const response: Pagination<FactorSetType> = yield call(FactorSetDepartureApi.fetchFactorSetDepartureTypes, paging);
        yield put(fetchFactorSetDepartureTypesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchFactorSetDepartureTypesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetDeparture(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDepartureApi.createFactorSetDeparture, factorSet);

        yield put(createFactorSetDepartureSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetDepartureError(error as HttpErrorResponse));
    }
}

function* doCreateDepartureVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDepartureApi.createDepartureVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createDepartureVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createDepartureVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDeparture(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDepartureApi.updateFactorSetDeparture, factorSet);

        yield put(updateFactorSetDepartureSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetDepartureError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetDeparture(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetDepartureApi.deactivateFactorSetDeparture, id);

        yield put(deactivateFactorSetDepartureSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetDepartureError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetDeparture/fetchFactorSetDepartureById', doFetchFactorSetDeparture),
    takeLatest('factorSetDeparture/fetchFactorSetDepartures', doFetchFactorSetDepartures),
    takeLatest('factorSetDeparture/fetchFactorSetDepartureTypes', doFetchFactorSetDepartureTypes),
    takeLatest('factorSetDeparture/createFactorSetDeparture', doCreateFactorSetDeparture),
    takeLatest('factorSetDeparture/createDepartureVersion', doCreateDepartureVersion),
    takeLatest('factorSetDeparture/updateFactorSetDeparture', doUpdateFactorSetDeparture),
    takeLatest('factorSetDeparture/deactivateFactorSetDeparture', doDeactivateFactorSetDeparture)
];