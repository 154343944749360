import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import ReportsApi from '../api/reportsApi';
import {
    exportKickbackAgreementPostenCSVError, exportKickbackAgreementPostenCSVSuccess,
    exportProvisionsCSVError, exportProvisionsCSVSuccess, exportReportCustomerTermsError, exportReportCustomerTermsSuccess, exportReportLinesError, exportReportLinesSuccess, fetchCalculatedPriceError,
    fetchCalculatedPriceSuccess, fetchKickBackPostenError, fetchKickBackPostenSuccess, fetchOrdersNotYetInvoicedError,
    fetchOrdersNotYetInvoicedFullReportError,
    fetchOrdersNotYetInvoicedFullReportSuccess,
    fetchOrdersNotYetInvoicedSuccess,
    fetchProvisionsError,
    fetchProvisionsSuccess,
    fetchReportCustomerTermsError,
    fetchReportCustomerTermSuccess,
    fetchReportHeaderError,
    fetchReportHeaderPivotedError,
    fetchReportHeaderPivotedSuccess,
    fetchReportHeaderSuccess,
    fetchReportLineByIdError,
    fetchReportLineByIdSuccess,
    fetchReportLinesError,
    fetchReportLinesSuccess
} from '../reducers/reportsReducer';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import CalculatedPriceFilters from '../interfaces/filters/calculatedPriceFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';
import FileWithName from '../interfaces/common/FileWithName';
import FileSaver from 'file-saver';
import KickbackAgreementFilters from '../interfaces/filters/kickbackAgreementFilters';
import Provision from '../interfaces/output/prevision';
import KickBackPosten from '../interfaces/output/kickBackPosten';
import ReportHeaderPivotedFilters from '../interfaces/filters/reportHeaderPivotedFilters';
import ReportHeaderPivoted from '../interfaces/output/ReportHeaderPivoted';
import ReportHeaderFilters from '../interfaces/filters/reportHeaderFilters';
import ReportHeader from '../interfaces/output/ReportHeader';
import ReportLineFilters from '../interfaces/filters/reportHeaderLineFilters';
import ReportLine from '../interfaces/output/ReportLine';
import ReportCustomerTerms from '../interfaces/output/ReportCustomerTerms';

function* doFetchOrdersNotYetInvoiced(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoiced, paging, filters);
        yield put(fetchOrdersNotYetInvoicedSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedError(error as HttpErrorResponse));
    }
}

function* doFetchOrdersNotYetInvoicedFullReport(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoicedFullReport, paging, filters);
        yield put(fetchOrdersNotYetInvoicedFullReportSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedFullReportError(error as HttpErrorResponse));
    }
}

function* doFetchCalculatedPrice(action: PayloadAction<{ paging: Paging, filters?: CalculatedPriceFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CalculatedPrice> = yield call(ReportsApi.fetchCalculatedPrice, paging, filters);
        yield put(fetchCalculatedPriceSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalculatedPriceError(error as HttpErrorResponse));
    }
}

function* doExportProvisionsCSV(action: PayloadAction<{ searchTerm?: string }>) {
    const { searchTerm } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exportProvisionsCSV, searchTerm);
        FileSaver.saveAs(data, name);
        yield put(exportProvisionsCSVSuccess());
    }

    catch (error: unknown) {
        yield put(exportProvisionsCSVError(error as HttpErrorResponse));
    }
}

function* doExportKickbackAgreementPostenCSV(action: PayloadAction<{ filters?: KickbackAgreementFilters }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exportKickbackAgreementPostenCSV, filters);
        FileSaver.saveAs(data, name);
        yield put(exportKickbackAgreementPostenCSVSuccess());
    }

    catch (error: unknown) {
        yield put(exportKickbackAgreementPostenCSVError(error as HttpErrorResponse));
    }
}

function* dofetchProvisions(action: PayloadAction<{ paging: Paging, searchTerm?: string }>) {
    const { paging, searchTerm } = action.payload;

    try {
        const response: Pagination<Provision> = yield call(ReportsApi.fetchProvisions, paging, searchTerm);
        yield put(fetchProvisionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProvisionsError(error as HttpErrorResponse));
    }
}

function* dofetchKickBackPosten(action: PayloadAction<{ paging: Paging, filters?: KickbackAgreementFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<KickBackPosten> = yield call(ReportsApi.fetchKickBackPosten, paging, filters);
        yield put(fetchKickBackPostenSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchKickBackPostenError(error as HttpErrorResponse));
    }
}

function* dofetchReportHeadersPivoted(action: PayloadAction<{ paging: Paging, filters?: ReportHeaderPivotedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ReportHeaderPivoted> = yield call(ReportsApi.fetchReportHeadersPivoted, paging, filters);
        yield put(fetchReportHeaderPivotedSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchReportHeaderPivotedError(error as HttpErrorResponse));
    }
}

function* dofetchReportHeaders(action: PayloadAction<{ paging: Paging, filters?: ReportHeaderFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ReportHeader> = yield call(ReportsApi.fetchReportHeaders, paging, filters);
        yield put(fetchReportHeaderSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchReportHeaderError(error as HttpErrorResponse));
    }
}

function* dofetchReportLines(action: PayloadAction<{ paging: Paging, filters?: ReportLineFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ReportLine> = yield call(ReportsApi.fetchReportLines, paging, filters);
        yield put(fetchReportLinesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchReportLinesError(error as HttpErrorResponse));
    }
}

function* doFetchReportLineById(action: PayloadAction<string>) {
    const reportLineId = action.payload;
    try {
        const response: ReportLine = yield call(ReportsApi.fetchReportLineById, reportLineId);
        yield put(fetchReportLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchReportLineByIdError(error as HttpErrorResponse));
    }
}

function* doExportReportLines(action: PayloadAction<{ filters?: ReportLineFilters }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exporReportLines, filters);
        FileSaver.saveAs(data, name);
        yield put(exportReportLinesSuccess());
    }

    catch (error: unknown) {
        yield put(exportReportLinesError(error as HttpErrorResponse));
    }
}

function* dofetchReportCustomerTerms(action: PayloadAction<{ paging: Paging, filters?: ReportCustomerTerms }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ReportCustomerTerms> = yield call(ReportsApi.fetchReportCustomerTerms, paging, filters);
        yield put(fetchReportCustomerTermSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchReportCustomerTermsError(error as HttpErrorResponse));
    }
}

function* doExportReportCustomerTerms(action: PayloadAction<{ filters?: ReportCustomerTerms }>) {
    const { filters } = action.payload;

    try {
        const { data, name }: FileWithName = yield call(ReportsApi.exporReportCustomerTerms, filters);
        FileSaver.saveAs(data, name);
        yield put(exportReportCustomerTermsSuccess());
    }

    catch (error: unknown) {
        yield put(exportReportCustomerTermsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('reports/fetchOrdersNotYetInvoiced', doFetchOrdersNotYetInvoiced),
    takeLatest('reports/fetchOrdersNotYetInvoicedFullReport', doFetchOrdersNotYetInvoicedFullReport),
    takeLatest('reports/fetchCalculatedPrice', doFetchCalculatedPrice),
    takeLatest('reports/exportProvisionsCSV', doExportProvisionsCSV),
    takeLatest('reports/exportKickbackAgreementPostenCSV', doExportKickbackAgreementPostenCSV),
    takeLatest('reports/fetchProvisions', dofetchProvisions),
    takeLatest('reports/fetchKickBackPosten', dofetchKickBackPosten),
    takeLatest('reports/fetchReportHeaderPivoted', dofetchReportHeadersPivoted),
    takeLatest('reports/fetchReportHeader', dofetchReportHeaders),
    takeLatest('reports/fetchReportLines', dofetchReportLines),
    takeLatest('reports/fetchReportLineById', doFetchReportLineById),
    takeLatest('reports/exportReportLines', doExportReportLines),
    takeLatest('reports/fetchReportCustomerTerms', dofetchReportCustomerTerms),
    takeLatest('reports/exportReportCustomerTerms', doExportReportCustomerTerms)
];

