import React from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import ActionRenderer from '../common/actionRenderer';
import CapriScheduledJobFilter from '../../interfaces/filters/capriScheduledJobFilter';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import GenericTextField from '../common/widgets/genericTextField';
import Action from '../../interfaces/common/action';

interface IFilterProps {
    filters: CapriScheduledJobFilter;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    procedureName: LanguageUtils.createMessage('Procedure name'),
    lastExecuted: LanguageUtils.createMessage('Last executed'),
    lastFinished: LanguageUtils.createMessage('last finished'),
    jobDescription: LanguageUtils.createMessage('Job description'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CapriScheduledJobFilterComponent(props: IFilterProps): JSX.Element {
    const { onChange, applyFilters, setDefaultState, filters, actions } = props;

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="procedureName"
                                label={messages.procedureName}
                                onChange={onChange}
                                value={filters.procedureName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="lastExecuted"
                            label={messages.lastExecuted}
                            value={filters?.lastExecuted}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="lastFinished"
                            label={messages.lastFinished}
                            value={filters?.lastFinished}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="jobDescription"
                                label={messages.jobDescription}
                                onChange={onChange}
                                value={filters.jobDescription}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}