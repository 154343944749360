import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Alert } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
// import WorkflowUtils from '../../../utils/workflowUtils';
import Loader from '../../common/widgets/loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrintAgreementVersionPeriodLinesOptions } from '../../../constants/printAgreementVersionPeriodLinesOptions';
import { isExportingPrintAgreementPeriodLines, exportPrintAgreementVersionPeriodLinesCSV, sendPrintAgreementVersionPeriodLines,
    exportPrintAgreementVersionPeriodLines } from '../../../reducers/printAgreementVersionReducer';
import If from '../../common/widgets/if';
import PrintAgreementVersionPeriodLineFilters from '../../../interfaces/filters/printAgreementVersionPeriodLineFilters';
import GenericTextField from '../../common/widgets/genericTextField';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    printAgreementVersionPeriodLines: LanguageUtils.createMessage('Print agreement version period lines'),
    sendPrintAgreementVersionPeriodLines: LanguageUtils.createMessage('Send Print agreement version period lines'),
    sendEmail: LanguageUtils.createMessage('Send email'),
    downloadCSV: LanguageUtils.createMessage('download csv'),
    cancel: LanguageUtils.createMessage('Cancel'),
    option1: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_1'),
    option2: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_2'),
    option3: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS_3'),
    chooseOption: LanguageUtils.createMessage('PRINT_DISPLAY_OPTIONS'),
    error: LanguageUtils.createMessage('VALIDATION_ERROR_SELECT_AN_OPTION'),
    infoMessage: LanguageUtils.createMessage('Info message to long time'),
    emailAddress: LanguageUtils.createMessage('EMAIL_ADDRESS'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format'),
    emailHelpText: LanguageUtils.createMessage('send email'),
    download: LanguageUtils.createMessage('download pdf')
};

interface ExportPrintAgreementVersionPeriodProps {
    open: boolean;
    setOpenModal: (sendEmail: boolean) => void;
    printAgreementVersionPeriodId: number | undefined;
    printAgreementVersionPeriodLineFilters: PrintAgreementVersionPeriodLineFilters;
    sendEmail: boolean;
}

export default function ExportPrintAgreementVersionPeriod({ open, setOpenModal, printAgreementVersionPeriodId,
    printAgreementVersionPeriodLineFilters, sendEmail }: ExportPrintAgreementVersionPeriodProps): JSX.Element {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('2');
    const [hasError, setHasError] = React.useState(false);
    const isExporting = useSelector(isExportingPrintAgreementPeriodLines);
    const [email, setEmail] = React.useState<string | undefined>();
    const [showValidationError, setShowValidationError] = React.useState<boolean | undefined>(false);
    const sendEmailForPdf = () => {
        if(!value) {
            setHasError(true);

            return;
        }
        const valid = ValidationUtils.validateEmail(email, true);
        setShowValidationError(!valid);
        if(!valid) {
            return;
        }
        dispatch(sendPrintAgreementVersionPeriodLines(
            {
                emailAddress: email,
                filters: {
                    ...printAgreementVersionPeriodLineFilters,
                    printAgreementDisplayOptionsId: parseInt(value, 10),
                    printAgreementVersionPeriodId: printAgreementVersionPeriodId
                }
            }));
        setOpenModal(false);
        setEmail('');
    };

    const exportPDF = () => {
        if(!value) {
            setHasError(true);

            return;
        }
        dispatch(exportPrintAgreementVersionPeriodLines(
            {
                filters: {
                    ...printAgreementVersionPeriodLineFilters,
                    printAgreementDisplayOptionsId: parseInt(value, 10),
                    printAgreementVersionPeriodId: printAgreementVersionPeriodId
                }
            }));
        setOpenModal(false);
    };

    const exportCSV = () => {
        if(!value) {
            setHasError(true);

            return;
        }
        dispatch(exportPrintAgreementVersionPeriodLinesCSV(
            {
                filters: {
                    ...printAgreementVersionPeriodLineFilters,
                    printAgreementDisplayOptionsId: parseInt(value, 10),
                    printAgreementVersionPeriodId: printAgreementVersionPeriodId
                }
            }));
        setOpenModal(false);
    };
    const { formatMessage } = useIntl();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setHasError(false);
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case 'name': {
                isValid = ValidationUtils.validateEmail(email, true);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
            <Loader isLoading={isExporting}>
                <>
                    <DialogTitle>
                        <If isTrue={sendEmail}>
                            <FormattedMessage {...messages.sendPrintAgreementVersionPeriodLines} />
                        </If>
                        <If isFalse={sendEmail}>
                            <FormattedMessage {...messages.printAgreementVersionPeriodLines} />
                        </If>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <If isTrue={sendEmail}>
                                <GenericTextField<string>
                                    name="name"
                                    label={messages.emailAddress}
                                    value={email}
                                    onChange={(_key: string, value: string | undefined) => setEmail(value)}
                                    error={setValidationState('name')}
                                    helperText={messages.emailHelpText}
                                />

                                {showValidationError &&
                                    <Alert severity="error">
                                        <FormattedMessage {...messages.wrongFormat} />
                                    </Alert>
                                }
                            </If>
                            <FormLabel id="demo-radio-buttons-group-label"><FormattedMessage {...messages.chooseOption} /></FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={onChange}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowAllAvailablePricesAndSurcharges} />}
                                    label={formatMessage(messages.option1)} />
                                <FormControlLabel value="2" control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowAllAvailablePricesAndSurchargesExceptEnergySurcharge} />}
                                    label={formatMessage(messages.option2)} />
                                <FormControlLabel value="3" control={<Radio checked={value === PrintAgreementVersionPeriodLinesOptions.ShowFreightPricesWithoutSurcharge} />}
                                    label={formatMessage(messages.option3)} />
                            </RadioGroup>
                            {/* <FormLabel color="warning" focused={true}>
                                <FormattedMessage {...messages.infoMessage} />
                            </FormLabel> */}
                            <If isTrue={hasError}>
                                <FormLabel error={hasError}>{formatMessage(messages.error)}</FormLabel>
                            </If>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenModal(false); setValue('2'); setShowValidationError(false); setEmail(''); }}><FormattedMessage {...messages.cancel} /></Button>
                        <If isTrue={sendEmail}>
                            <Button onClick={sendEmailForPdf}><FormattedMessage {...messages.sendEmail} /></Button>
                        </If>
                        <If isFalse={sendEmail}>
                            <Button onClick={exportCSV}><FormattedMessage {...messages.downloadCSV} /></Button>
                        </If>
                        <If isFalse={sendEmail}>
                            <Button onClick={exportPDF}><FormattedMessage {...messages.download} /></Button>
                        </If>
                    </DialogActions>
                </>
            </Loader>

        </Dialog>
    );
}