import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import Action from '../../../interfaces/common/action';
import ActionRenderer from '../../common/actionRenderer';
import ReportLineFilters from '../../../interfaces/filters/reportHeaderLineFilters';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import PageUtils from '../../../utils/pageUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';

interface IFilterProps {
    filters: ReportLineFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    customerName: LanguageUtils.createMessage('customer Name'),
    gtsCustomerNo: LanguageUtils.createMessage('gts customer number'),
    fromPlace: LanguageUtils.createMessage('From place'),
    toPlace: LanguageUtils.createMessage('To place'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function ReportLineFiltersComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const terminals = useSelector(getTerminals).content;

    useEffect(() => {
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="gtsCustomerNo"
                                label={messages.gtsCustomerNo}
                                type="number"
                                onChange={onChange}
                                value={filters.gtsCustomerNo}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="gtsCustomerName"
                                label={messages.customerName}
                                onChange={onChange}
                                value={filters.gtsCustomerName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromPlace}
                            onChange={(obj: Terminal | null) => onChange('fromPlace', obj?.gtsTerminalCode)}
                            placeholder={messages.fromPlace}
                            compareFn={(o: Terminal) => o.gtsTerminalCode === filters.fromPlace}
                            getOptionLabel={(option: Terminal) => option?.gtsTerminalCode || ''}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toPlace}
                            onChange={(obj: Terminal | null) => onChange('toPlace', obj?.gtsTerminalCode)}
                            placeholder={messages.toPlace}
                            compareFn={(o: Terminal) => o.gtsTerminalCode === filters.toPlace}
                            getOptionLabel={(option: Terminal) => option?.gtsTerminalCode || ''}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}