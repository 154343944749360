import { combineReducers } from 'redux';
import { routerReducer as router, RouterState } from 'react-router-redux';
import customerReducer, { CustomerState } from '../reducers/customerReducer';
import countryReducer, { CountryState } from '../reducers/countryReducer';
import sellerReducer, { SellerState } from '../reducers/sellerReducer';
import statusReducer, { StatusState } from '../reducers/statusReducer';
import customerCorporateReducer, { CustomerCorporateState } from '../reducers/customerCorporateReducer';
import invoiceFrequencyReducer, { InvoiceFrequencyState } from '../reducers/invoiceFrequencyReducer';
import segmentReducer, { CustomerSegmentState } from '../reducers/segmentReducer';
import deadlineReducer, { PaymentDeadlineState } from '../reducers/paymentDeadlineReducer';
import invoiceSeparationReducer, { InvoiceSeparationState } from '../reducers/invoiceSeparationReducer';
import customerGroupReducer, { CustomerGroupState } from '../reducers/customerGroupReducer';
import agreementReducer, { AgreementState } from '../reducers/agreementsReducer';
import unitGroupReducer, { UnitGroupState } from '../reducers/unitGroupReducer';
import customerAgreementReducer, { CustomerAgreementState } from '../reducers/customerAgreementReducer';
import productArenaReducer, { ProductArenaState } from '../reducers/productArenaReducer';
import agreementVersionReducer, { AgreementVersionState } from '../reducers/agreementVersionReducer';
import agreementVersionStatusReducer, { AgreementVersionStatusState } from '../reducers/agreementVersionStatusReducer';
import productReducer, { ProductState } from '../reducers/productReducer';
import terminalReducer, { TerminalState } from '../reducers/terminalReducer';
import productTypeReducer, { ProductTypeState } from '../reducers/productTypesReducer';
import pricelistReducer, { PriceListState } from '../reducers/priceListReducer';
import currencyReducer, { CurrencyState } from '../reducers/currencyReducer';
import pricelistTypeReducer, { PriceListTypeState } from '../reducers/pricelistTypeReducer';
import unitSubgroupReducer, { UnitSubgroupState } from '../reducers/unitSubgroupReducer';
import priceListVersionReducer, { PriceListVersionState } from '../reducers/priceListVersionReducer';
import pricelistVersionStatusReducer, { PriceListVersionStatusState } from '../reducers/pricelistVersionStatusReducer';
import vatReducer, { VatState } from '../reducers/vatReducer';
import dynamicsReducer, { DynamicsState } from '../reducers/dynamicsReducer';
import agreementVersionPricelistReducer, { AgreementVersionPriceListState } from '../reducers/agreementVersionPriceListReducer';
import factorSetWeightReducer, { FactorSetWeightState } from '../reducers/factorSetWeightReducer';
import factorSetPriorityReducer, { FactorSetPriorityState } from '../reducers/factorSetPriorityReducer';
import factorSetDepartureReducer, { FactorSetDepartureState } from '../reducers/factorSetDepartureReducer';
import gtsReducer, { GTSState } from '../reducers/gtsReducer';
import errorReducer, { ErrorState } from '../reducers/errorReducer';
import pricelistVersionProductReducer, { PricelistVersionProductState } from '../reducers/pricelistVersionProductReducer';
import userLoginReducer, { UserState } from '../reducers/userReducer';
import transitDiscountReducer, { TransitDiscountState } from '../reducers/transitDiscountReducer';
import gtsOrderReducer, { GtsOrderState } from '../reducers/gtsOrderReducer';
import unitTypeReducer, { UnitTypeState } from '../reducers/unitTypeReducer';
import gtsOrderUnitReducer, { GTSOrderUnitState } from '../reducers/gtsOrderUnitReducer';
import gtsOrderUnitDepotReducer, { GTSOrderUnitDepotState } from '../reducers/gtsOrderUnitDepotLinesReducer';
import dangerousGoodsReducer, { GTSOrderUnitDangerousGoodsState } from '../reducers/gtsDangerousGoodsReducer';
import gtsUnitServiceReducer, { GtsUnitServiceState } from '../reducers/gtsUnitServiceReducer';
import orderReducer, { OrderState } from '../reducers/orderReducer';
import invoiceTypeReducer, { InvoiceTypeState } from '../reducers/invoiceTypeReducer';
import importExportCodeReducer, { ImportExportCodeState } from '../reducers/importExportCodeReducer';
import cancellationTypeReducer, { CancellationTypeState } from '../reducers/cancellationTypeReducer';
import serviceReducer, { ServiceState } from '../reducers/serviceReducer';
import serviceStatusReducer, { ServiceStatusState } from '../reducers/serviceStatusReducer';
import serviceTypeReducer, { ServiceTypeState } from '../reducers/serviceTypeReducer';
import orderUnitReducer, { OrderUnitState } from '../reducers/orderUnitReducer';
import serviceProductReducer, { ServiceProductState } from '../reducers/serviceProductReducer';
import unitServiceReducer, { UnitServiceState } from '../reducers/unitServiceReducer';
import dangerousGoodsGroupReducer, { DangerousGoodsGroupState } from '../reducers/dangerousGoodsGroupReducer';
import orderUnitServiceProductReducer, { UnitServiceProductState } from '../reducers/orderUnitServiceProductReducer';
import dashboardMessageTypeReducer, { DashboardMessageTypeState } from '../reducers/dashboardMessageTypeReducer';
import dashboardMessageReducer, { DashboardMessageState } from '../reducers/dashboardMessageReducer';
import ousStatusReducer, { OUSStatusState } from '../reducers/ousStatusReducer';
import errorTypeReducer, { ErrorTypeState } from '../reducers/errorTypeReducer';
import orderStatusReducer, { OrderStatusState } from '../reducers/orderStatusReducer';
import ouspStatusReducer, { OUSPStatusState } from '../reducers/ouspStatusReducer';
import factorSetHoursReducer, { FactorSetHoursState } from '../reducers/factorSetHoursReducer';
import factorSetCancellationReducer, { FactorSetCancellationState } from '../reducers/factorSetCancellationReducer';
import agreementVersionSpecialPriceReducer, { AgreementVersionSpecialPriceState } from '../reducers/agreementVersionSpecialPriceReducer';
import invoiceReducer, { InvoiceState } from '../reducers/invoiceReducer';
import invoiceLineReducer, { InvoiceLineState } from '../reducers/invoiceLineReducer';
import invoiceFormatReducer, { InvoiceFormatState } from '../reducers/invoiceFormatReducer';
import invoiceStatusReducer, { InvoiceStatusState } from '../reducers/invoiceStatusReducer';
import simulationReducer, { SimulationState } from '../reducers/simulationReducer';
import simulationOrderReducer, { SimulationOrderState } from '../reducers/simulationOrderReducer';
import simulationProductReducer, { SimulationProductState } from '../reducers/simulationProductReducer';
import simulationStatusReducer, { SimulationStatusState } from '../reducers/simulationStatusReducer';
import factorSetDepartureVersionReducer, { FactorSetDepartureVersionState } from '../reducers/factorSetDepartureVersionReducer';
import departureLineReducer, { DepartureLineState } from '../reducers/departureLineReducer';
import cancellationLineReducer, { CancellationLineState } from '../reducers/cancellationLineReducer';
import priorityLineReducer, { PriorityLineState } from '../reducers/priorityLineReducer';
import hoursLineReducer, { HoursLineState } from '../reducers/hoursLineReducer';
import weightLineReducer, { WeightLineState } from '../reducers/weightLineReducer';
import departureVersionStatusReducer, { DepartureVersionStatusState } from '../reducers/departureVersionStatusReducer';
import factorSetCancellationVersionReducer, { FactorSetCancellationVersionState } from '../reducers/factorSetCancellationVersionReducer';
import factorSetHoursVersionReducer, { FactorSetHoursVersionState } from '../reducers/factorSetHoursVersionReducer';
import factorSetPriorityVersionReducer, { FactorSetPriorityVersionState } from '../reducers/factorSetPriorityVersionReducer';
import factorSetWeightVersionReducer, { FactorSetWeightVersionState } from '../reducers/factorSetWeightVersionReducer';
import priorityVersionStatusReducer, { PriorityVersionStatusState } from '../reducers/priorityVersionStatusReducer';
import cancellationVersionStatusReducer, { CancellationVersionStatusState } from '../reducers/cancellationVersionStatusReducer';
import hoursVersionStatusReducer, { HoursVersionStatusState } from '../reducers/hoursVersionStatusReducer';
import weightVersionStatusReducer, { WeightVersionStatusState } from '../reducers/weightVersionStatusReducer';
import priorityTypeReducer, { PriorityTypeState } from '../reducers/priorityTypeReducer';
import factorSetDangerousGoodsReducer, { FactorSetDangerousGoodsState } from '../reducers/factorSetDangerousGoodsReducer';
import factorSetCalendarReducer, { FactorSetCalendarState } from '../reducers/factorSetCalendarReducer';
import surchargeReducer, { SurchargeState } from '../reducers/surchargeReducer';
import dangerousGoodsLineReducer, { DangerousGoodLineState } from '../reducers/dangerousGoodsLineReducer';
import helptextReducer, { HelptextState } from '../reducers/helptextReducer';
import calendarLineReducer, { CalendarLineState } from '../reducers/calendarLineReducer';
import factorSetDangerousGoodsVersionReducer, { FactorSetDangerousGoodVersionState } from '../reducers/factorSetDangerousGoodsVersionReducer';
import factorSetCalendarVersionReducer, { FactorSetCalendarVersionState } from '../reducers/factorSetCalendarVersionReducer';
import dangerousGoodsVersionStatusReducer, { DangerousGoodVersionStatusState } from '../reducers/dangerousGoodsVersionStatusReducer';
import calendarVersionStatusReducer, { CalendarVersionStatusState } from '../reducers/calendarVersionStatusReducer';
import departureTypeReducer, { DepartureTypeState } from '../reducers/departureTypeReducer';
import exportInvoicesReducer, { ExportInvoiceState } from '../reducers/d365invoiceReducer';
import pricingComponentTypeReducer, { PricingComponentTypeState } from '../reducers/pricingComponentTypeReducer';
import pricelistUnitTypeReducer, { PriceListUnitTypeState } from '../reducers/pricelistUnitTypeReducer';
import unitStatusReducer, { UnitStatusState } from '../reducers/unitStatusReducer';
import agreementVersionTransitDiscountReducer, { AgreementVersionTransitDiscountState } from '../reducers/agreementVersionTransitDiscountReducer';
import loadedFlagReducer, { LoadedFlagState } from '../reducers/loadedFlagReducer';
import languageReducer, { LanguageState } from '../reducers/languageReducer';
import surchargeRateUnitReducer, { SurchargeRateUnitState } from '../reducers/surchargeRateUnitReducer';
import surchargeAreaReducer, { SurchargeAreaState } from '../reducers/surchargeAreaReducer';
import surchargeRateReducer, { SurchargeRateState } from '../reducers/surchargeRateReducer';
import surchargeTypeReducer, { SurchargeTypeState } from '../reducers/surchargeTypeReducer';
import userRoleReducer, { UserRoleState } from '../reducers/userRoleReducer';
import userStatusReducer, { UserStatusState } from '../reducers/userStatusReducer';
import serviceArenaReducer, { ServiceArenaState } from '../reducers/serviceArenaReducer';
import serviceArenaTypeReducer, { ServiceArenaTypeState } from '../reducers/serviceArenaTypeReducer';
import terminalOperatorReducer, { TerminalOperatorState } from '../reducers/terminalOperatorReducer';
import responsibilityCenterReducer, { ResponsibilityCenterState } from '../reducers/responsibilityCenterReducer';
import productArenaTypeReducer, { ProductArenaTypeState } from '../reducers/productArenaTypeReducer';
import priceListTerminalReducer, { PriceListTerminalState } from '../reducers/priceListTerminalReducer';
import reportsReducer, { ReportsState } from '../reducers/reportsReducer';
import printAgreementVersionReducer, { PrintAgreementVersionState } from '../reducers/printAgreementVersionReducer';
import scheduledJobReducer, { ScheduledJobState } from '../reducers/scheduledJobReducer';
// interface StoreEnhancerState {}
export interface RootState {
  router: RouterState;
  customerReducer: CustomerState;
  countryReducer: CountryState;
  sellerReducer: SellerState;
  statusReducer: StatusState;
  customerCorporateReducer: CustomerCorporateState;
  invoiceFrequencyReducer: InvoiceFrequencyState;
  segmentReducer: CustomerSegmentState;
  deadlineReducer: PaymentDeadlineState;
  invoiceSeparationReducer: InvoiceSeparationState;
  customerGroupReducer: CustomerGroupState;
  agreementReducer: AgreementState;
  unitGroupReducer: UnitGroupState;
  customerAgreementReducer: CustomerAgreementState;
  productArenaReducer: ProductArenaState;
  agreementVersionReducer: AgreementVersionState;
  agreementVersionStatusReducer: AgreementVersionStatusState;
  productReducer: ProductState;
  terminalReducer: TerminalState;
  productTypeReducer: ProductTypeState;
  pricelistReducer: PriceListState;
  currencyReducer: CurrencyState;
  pricelistTypeReducer: PriceListTypeState;
  unitSubgroupReducer: UnitSubgroupState;
  priceListVersionReducer: PriceListVersionState;
  pricelistVersionStatusReducer: PriceListVersionStatusState;
  vatReducer: VatState;
  dynamicsReducer: DynamicsState;
  agreementVersionPricelistReducer: AgreementVersionPriceListState;
  factorSetWeightReducer: FactorSetWeightState;
  factorSetPriorityReducer: FactorSetPriorityState;
  factorSetDepartureReducer: FactorSetDepartureState;
  gtsReducer: GTSState;
  errorReducer: ErrorState;
  pricelistVersionProductReducer: PricelistVersionProductState;
  userLoginReducer: UserState;
  transitDiscountReducer: TransitDiscountState;
  gtsOrderReducer: GtsOrderState;
  unitTypeReducer: UnitTypeState;
  gtsOrderUnitReducer: GTSOrderUnitState;
  gtsOrderUnitDepotReducer: GTSOrderUnitDepotState;
  gtsDangerousGoodsReducer: GTSOrderUnitDangerousGoodsState;
  gtsUnitServiceReducer: GtsUnitServiceState;
  orderReducer: OrderState;
  invoiceTypeReducer: InvoiceTypeState;
  importExportCodeReducer: ImportExportCodeState;
  cancellationTypeReducer: CancellationTypeState;
  serviceReducer: ServiceState;
  serviceTypeReducer: ServiceTypeState;
  serviceStatusReducer: ServiceStatusState;
  orderUnitReducer: OrderUnitState;
  serviceProductReducer: ServiceProductState;
  unitServiceReducer: UnitServiceState;
  dangerousGoodsGroupReducer: DangerousGoodsGroupState;
  orderUnitServiceProductReducer: UnitServiceProductState;
  dashboardMessageTypeReducer: DashboardMessageTypeState;
  dashboardMessageReducer: DashboardMessageState;
  ousStatusReducer: OUSStatusState;
  errorTypeReducer: ErrorTypeState;
  orderStatusReducer: OrderStatusState;
  ouspStatusReducer: OUSPStatusState;
  factorSetHoursReducer: FactorSetHoursState;
  factorSetCancellationReducer: FactorSetCancellationState;
  agreementVersionSpecialPriceReducer: AgreementVersionSpecialPriceState;
  invoiceReducer: InvoiceState;
  invoiceLineReducer: InvoiceLineState;
  invoiceFormatReducer: InvoiceFormatState;
  invoiceStatusReducer: InvoiceStatusState;
  simulationReducer: SimulationState;
  simulationOrderReducer: SimulationOrderState;
  simulationProductReducer: SimulationProductState;
  simulationStatusReducer: SimulationStatusState;
  factorSetDepartureVersionReducer: FactorSetDepartureVersionState;
  departureLineReducer: DepartureLineState;
  cancellationLineReducer: CancellationLineState;
  priorityLineReducer: PriorityLineState;
  hoursLineReducer: HoursLineState;
  weightLineReducer: WeightLineState;
  departureVersionStatusReducer: DepartureVersionStatusState;
  factorSetCancellationVersionReducer: FactorSetCancellationVersionState;
  factorSetHoursVersionReducer: FactorSetHoursVersionState;
  factorSetPriorityVersionReducer: FactorSetPriorityVersionState;
  factorSetWeightVersionReducer: FactorSetWeightVersionState;
  priorityVersionStatusReducer: PriorityVersionStatusState;
  cancellationVersionStatusReducer: CancellationVersionStatusState;
  hoursVersionStatusReducer: HoursVersionStatusState;
  weightVersionStatusReducer: WeightVersionStatusState;
  priorityTypeReducer: PriorityTypeState;
  helptextReducer: HelptextState;
  factorSetDangerousGoodsReducer: FactorSetDangerousGoodsState;
  factorSetCalendarReducer: FactorSetCalendarState;
  surchargeReducer: SurchargeState;
  dangerousGoodsLineReducer: DangerousGoodLineState;
  calendarLineReducer: CalendarLineState;
  factorSetDangerousGoodVersionReducer: FactorSetDangerousGoodVersionState;
  factorSetCalendarVersionReducer: FactorSetCalendarVersionState;
  dangerousGoodVersionStatusReducer: DangerousGoodVersionStatusState;
  calendarVersionStatusReducer: CalendarVersionStatusState;
  departureTypeReducer: DepartureTypeState;
  exportInvoicesReducer: ExportInvoiceState;
  pricingComponentTypeReducer: PricingComponentTypeState;
  pricelistUnitTypeReducer: PriceListUnitTypeState;
  unitStatusReducer: UnitStatusState;
  agreementVersionTransitDiscountReducer: AgreementVersionTransitDiscountState;
  loadedFlagReducer: LoadedFlagState;
  languageReducer: LanguageState;
  surchargeRateUnitReducer: SurchargeRateUnitState;
  surchargeAreaReducer: SurchargeAreaState;
  surchargeRateReducer: SurchargeRateState;
  surchargeTypeReducer: SurchargeTypeState;
  userRoleReducer: UserRoleState;
  userStatusReducer: UserStatusState;
  serviceArenaReducer: ServiceArenaState;
  serviceArenaTypeReducer: ServiceArenaTypeState;
  terminalOperatorReducer: TerminalOperatorState;
  responsibilityCenterReducer: ResponsibilityCenterState;
  productArenaTypeReducer: ProductArenaTypeState;
  priceListTerminalReducer: PriceListTerminalState;
  reportsReducer: ReportsState;
  printAgreementVersionReducer: PrintAgreementVersionState;
  scheduledJobReducer: ScheduledJobState;
}

export const rootReducer = combineReducers<RootState>({
    router,
    customerReducer: customerReducer,
    countryReducer: countryReducer,
    sellerReducer: sellerReducer,
    statusReducer: statusReducer,
    customerCorporateReducer: customerCorporateReducer,
    invoiceFrequencyReducer: invoiceFrequencyReducer,
    segmentReducer: segmentReducer,
    deadlineReducer: deadlineReducer,
    invoiceSeparationReducer: invoiceSeparationReducer,
    customerGroupReducer: customerGroupReducer,
    agreementReducer: agreementReducer,
    unitGroupReducer: unitGroupReducer,
    customerAgreementReducer: customerAgreementReducer,
    productArenaReducer: productArenaReducer,
    agreementVersionReducer: agreementVersionReducer,
    agreementVersionStatusReducer: agreementVersionStatusReducer,
    productReducer: productReducer,
    terminalReducer: terminalReducer,
    productTypeReducer: productTypeReducer,
    pricelistReducer: pricelistReducer,
    currencyReducer: currencyReducer,
    pricelistTypeReducer: pricelistTypeReducer,
    unitSubgroupReducer: unitSubgroupReducer,
    priceListVersionReducer: priceListVersionReducer,
    pricelistVersionStatusReducer: pricelistVersionStatusReducer,
    vatReducer: vatReducer,
    dynamicsReducer: dynamicsReducer,
    agreementVersionPricelistReducer: agreementVersionPricelistReducer,
    factorSetWeightReducer: factorSetWeightReducer,
    factorSetPriorityReducer: factorSetPriorityReducer,
    factorSetDepartureReducer: factorSetDepartureReducer,
    gtsReducer: gtsReducer,
    errorReducer: errorReducer,
    pricelistVersionProductReducer: pricelistVersionProductReducer,
    userLoginReducer: userLoginReducer,
    transitDiscountReducer: transitDiscountReducer,
    gtsOrderReducer: gtsOrderReducer,
    unitTypeReducer: unitTypeReducer,
    gtsOrderUnitReducer: gtsOrderUnitReducer,
    gtsOrderUnitDepotReducer: gtsOrderUnitDepotReducer,
    gtsDangerousGoodsReducer: dangerousGoodsReducer,
    gtsUnitServiceReducer: gtsUnitServiceReducer,
    orderReducer: orderReducer,
    invoiceTypeReducer: invoiceTypeReducer,
    importExportCodeReducer: importExportCodeReducer,
    cancellationTypeReducer: cancellationTypeReducer,
    serviceReducer: serviceReducer,
    serviceTypeReducer: serviceTypeReducer,
    serviceStatusReducer: serviceStatusReducer,
    orderUnitReducer: orderUnitReducer,
    serviceProductReducer: serviceProductReducer,
    unitServiceReducer: unitServiceReducer,
    dangerousGoodsGroupReducer: dangerousGoodsGroupReducer,
    orderUnitServiceProductReducer: orderUnitServiceProductReducer,
    dashboardMessageTypeReducer: dashboardMessageTypeReducer,
    dashboardMessageReducer: dashboardMessageReducer,
    ousStatusReducer: ousStatusReducer,
    errorTypeReducer: errorTypeReducer,
    orderStatusReducer: orderStatusReducer,
    ouspStatusReducer: ouspStatusReducer,
    factorSetHoursReducer: factorSetHoursReducer,
    factorSetCancellationReducer: factorSetCancellationReducer,
    agreementVersionSpecialPriceReducer: agreementVersionSpecialPriceReducer,
    invoiceReducer: invoiceReducer,
    invoiceLineReducer: invoiceLineReducer,
    invoiceFormatReducer: invoiceFormatReducer,
    invoiceStatusReducer: invoiceStatusReducer,
    simulationReducer: simulationReducer,
    simulationOrderReducer: simulationOrderReducer,
    simulationProductReducer: simulationProductReducer,
    simulationStatusReducer: simulationStatusReducer,
    factorSetDepartureVersionReducer: factorSetDepartureVersionReducer,
    departureLineReducer: departureLineReducer,
    cancellationLineReducer: cancellationLineReducer,
    priorityLineReducer: priorityLineReducer,
    hoursLineReducer: hoursLineReducer,
    weightLineReducer: weightLineReducer,
    departureVersionStatusReducer: departureVersionStatusReducer,
    factorSetCancellationVersionReducer: factorSetCancellationVersionReducer,
    factorSetHoursVersionReducer: factorSetHoursVersionReducer,
    factorSetPriorityVersionReducer: factorSetPriorityVersionReducer,
    factorSetWeightVersionReducer: factorSetWeightVersionReducer,
    priorityVersionStatusReducer: priorityVersionStatusReducer,
    cancellationVersionStatusReducer: cancellationVersionStatusReducer,
    hoursVersionStatusReducer: hoursVersionStatusReducer,
    weightVersionStatusReducer: weightVersionStatusReducer,
    priorityTypeReducer: priorityTypeReducer,
    helptextReducer: helptextReducer,
    factorSetDangerousGoodsReducer: factorSetDangerousGoodsReducer,
    factorSetCalendarReducer: factorSetCalendarReducer,
    surchargeReducer: surchargeReducer,
    dangerousGoodsLineReducer: dangerousGoodsLineReducer,
    calendarLineReducer: calendarLineReducer,
    factorSetDangerousGoodVersionReducer: factorSetDangerousGoodsVersionReducer,
    factorSetCalendarVersionReducer: factorSetCalendarVersionReducer,
    dangerousGoodVersionStatusReducer: dangerousGoodsVersionStatusReducer,
    calendarVersionStatusReducer: calendarVersionStatusReducer,
    departureTypeReducer: departureTypeReducer,
    exportInvoicesReducer: exportInvoicesReducer,
    pricingComponentTypeReducer: pricingComponentTypeReducer,
    pricelistUnitTypeReducer: pricelistUnitTypeReducer,
    unitStatusReducer: unitStatusReducer,
    agreementVersionTransitDiscountReducer: agreementVersionTransitDiscountReducer,
    loadedFlagReducer: loadedFlagReducer,
    languageReducer: languageReducer,
    surchargeRateUnitReducer: surchargeRateUnitReducer,
    surchargeAreaReducer: surchargeAreaReducer,
    surchargeRateReducer: surchargeRateReducer,
    surchargeTypeReducer: surchargeTypeReducer,
    userRoleReducer: userRoleReducer,
    userStatusReducer: userStatusReducer,
    serviceArenaReducer: serviceArenaReducer,
    serviceArenaTypeReducer: serviceArenaTypeReducer,
    terminalOperatorReducer: terminalOperatorReducer,
    responsibilityCenterReducer: responsibilityCenterReducer,
    productArenaTypeReducer: productArenaTypeReducer,
    priceListTerminalReducer: priceListTerminalReducer,
    reportsReducer: reportsReducer,
    printAgreementVersionReducer: printAgreementVersionReducer,
    scheduledJobReducer: scheduledJobReducer
});