import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import PriorityType from '../interfaces/output/priorityType';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PriorityTypeApi {
    static fetchPriorityTypes = async(paging: Paging): Promise<Pagination<PriorityType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/prioritytypes');
        const { data } = await http.get<Pagination<PriorityType>>(url);

        return data;
    };
}
