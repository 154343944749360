import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import { fetchReportHeaderPivoted, getReportHeadersPivoted, isLoadingReportHeaderPivoted, resetReportHeaderPivoted } from '../../../reducers/reportsReducer';
import UrlConstants from '../../../constants/UrlConstants';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import PageUtils from '../../../utils/pageUtils';
import ReportHeaderPivotedFiltersComponent from './reportHeadersPivotedFilter';
import ReportHeadersPivotedList from './reportHeadersPivotedList';
import ReportHeaderPivoted from '../../../interfaces/output/ReportHeaderPivoted';
import ReportHeaderPivotedFilters from '../../../interfaces/filters/reportHeaderPivotedFilters';

interface IReportHeaderPivotedProps {
    classes: any;
    exportKickbackAgreementPostenCSV: any;
    isLoading: boolean;
    onChange: () => void;
    resetReportHeaderPivoted: () => void;
    fetchReportHeaderPivoted: any;
    fetch: any;
    reportHeadersPivoted: Pagination<ReportHeaderPivoted>;
}

interface IReportHeaderPivotedPropState {
    filters: ReportHeaderPivotedFilters;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    reportHeaderPivoted: LanguageUtils.createMessage('Report header pivoted')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchReportHeaderPivoted: (paging: Paging, filters: ReportHeaderPivotedFilters) => dispatch(fetchReportHeaderPivoted({
        paging,
        filters
    })),
    resetReportHeaderPivoted: () => dispatch(resetReportHeaderPivoted())
});

const mapStoreToProps = (store: RootState) => ({
    isLoading: isLoadingReportHeaderPivoted(store),
    reportHeadersPivoted: getReportHeadersPivoted(store)
});

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class KickbackAgreementCSV extends Component<IReportHeaderPivotedProps, IReportHeaderPivotedPropState> {
    reportHeaderPivotedRef: any;
    constructor(props: IReportHeaderPivotedProps) {
        super(props);

        this.state = {
            filters: {} as ReportHeaderPivotedFilters,
            menuItem: {
                id: 1,
                name: messages.reportHeaderPivoted,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            },
            paging: PageUtils.getDefaultPaging([{
                field: 'departureYear',
                sort: 'desc' as GridSortDirection
            } as GridSortItem,
            {
                field: 'departureMonth',
                sort: 'desc' as GridSortDirection
            } as GridSortItem]),
            sortModel: [{
                field: 'departureYear',
                sort: 'desc' as GridSortDirection
            } as GridSortItem,
            {
                field: 'departureMonth',
                sort: 'desc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchReportHeaderPivoted } = this.props;
        const { paging, filters } = this.state;

        fetchReportHeaderPivoted(paging, filters);
    }

    componentWillUnmount() {
        const { resetReportHeaderPivoted } = this.props;
        resetReportHeaderPivoted();
    }

    applyFilters = () => {
        const { fetchReportHeaderPivoted } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchReportHeaderPivoted(newPagination, filters);

        if(this.reportHeaderPivotedRef !== null && this.reportHeaderPivotedRef !== undefined &&
            this.reportHeaderPivotedRef.current !== null && this.reportHeaderPivotedRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.reportHeaderPivotedRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as ReportHeaderPivotedFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchReportHeaderPivoted } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'departureYear',
            sort: 'desc' as GridSortDirection
        } as GridSortItem,
        {
            field: 'departureMonth',
            sort: 'desc' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
        } as ReportHeaderPivotedFilters;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchReportHeaderPivoted(newPagination, newFilters);

        if(this.reportHeaderPivotedRef !== null && this.reportHeaderPivotedRef !== undefined &&
            this.reportHeaderPivotedRef.current !== null && this.reportHeaderPivotedRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.reportHeaderPivotedRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchReportHeaderPivoted } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchReportHeaderPivoted(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoading, reportHeadersPivoted, fetchReportHeaderPivoted } = this.props;
        const { filters, menuItem, paging, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
            >
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ReportHeaderPivotedFiltersComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <ReportHeadersPivotedList
                                    rows={reportHeadersPivoted} isLoading={isLoading}
                                    ref={this.reportHeaderPivotedRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchReportHeaderPivoted(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(KickbackAgreementCSV));