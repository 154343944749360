import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import TableUtils from '../../utils/tableUtils';
import TruncatedStringTooltip from '../common/truncatedStringTooltip';
import DataGridComponent from '../common/dataGrid';
import NullableFormattedDate from '../common/nullableFormattedDate';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';

interface CapriScheduledJobListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    runOnMonday: LanguageUtils.createMessage('Run on monday'),
    runOnTuesday: LanguageUtils.createMessage('Run on tuesday'),
    runOnWednesday: LanguageUtils.createMessage('Run on wednesday'),
    runOnThursday: LanguageUtils.createMessage('Run on thursday'),
    runOnFriday: LanguageUtils.createMessage('Run on friday'),
    runOnSaturday: LanguageUtils.createMessage('Run on saturday'),
    runOnSunday: LanguageUtils.createMessage('Run on sunday'),
    runFromDayInMonth: LanguageUtils.createMessage('Run from day in month'),
    runToDayInMonth: LanguageUtils.createMessage('Run to day in month '),
    runFromHours: LanguageUtils.createMessage('Run from hours'),
    runToHours: LanguageUtils.createMessage('Run to hours '),
    runEveryXXXMinutes: LanguageUtils.createMessage('Run every xxx minutes'),
    lastExecuted: LanguageUtils.createMessage('Last executed'),
    lastFinished: LanguageUtils.createMessage('Last finished'),
    stillRunning: LanguageUtils.createMessage('Still running'),
    jobDescription: LanguageUtils.createMessage('Job description'),
    procedureName: LanguageUtils.createMessage('Procedure name'),
    errorMessage: LanguageUtils.createMessage('Error message')
};

const ReportCustomerTermsList = forwardRef((props: CapriScheduledJobListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'procedureName',
                headerName: intl.formatMessage(messages.procedureName),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.procedureName}
                        link={`/#/${UrlConstants.CAPRI_SCHEDULED_JOB}/${applyRowFn(cellValues)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('procedureName', hiddenColumns)
            },
            {
                field: 'runOnMonday',
                headerName: intl.formatMessage(messages.runOnMonday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={cellValues.row?.runOnMonday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnMonday', hiddenColumns)
            },
            {
                field: 'runOnTuesday',
                headerName: `${intl.formatMessage(messages.runOnTuesday)}`,
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={cellValues.row?.runOnTuesday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnTuesday', hiddenColumns)
            },
            {
                field: 'runOnWednesday',
                headerName: intl.formatMessage(messages.runOnWednesday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runOnWednesday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnWednesday', hiddenColumns)
            },
            {
                field: 'runOnThursday',
                headerName: intl.formatMessage(messages.runOnThursday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runOnThursday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnThursday', hiddenColumns)
            },
            {
                field: 'runOnFriday',
                headerName: intl.formatMessage(messages.runOnFriday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runOnFriday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnFriday', hiddenColumns)
            },
            {
                field: 'runOnSaturday',
                headerName: intl.formatMessage(messages.runOnSaturday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runOnSaturday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnSaturday', hiddenColumns)
            },
            {
                field: 'runOnSunday',
                headerName: intl.formatMessage(messages.runOnSunday),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runOnSunday?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runOnSunday', hiddenColumns)
            },
            {
                field: 'runFromDayInMonth',
                headerName: intl.formatMessage(messages.runFromDayInMonth),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runFromDayInMonth} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runFromDayInMonth', hiddenColumns)
            },
            {
                field: 'runToDayInMonth',
                headerName: intl.formatMessage(messages.runToDayInMonth),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runToDayInMonth} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runToDayInMonth', hiddenColumns)
            },
            {
                field: 'runFromHours',
                headerName: intl.formatMessage(messages.runFromHours),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runFromHours} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runFromHours', hiddenColumns)
            },
            {
                field: 'runToHours',
                headerName: intl.formatMessage(messages.runToHours),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runToHours} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runToHours', hiddenColumns)
            },
            {
                field: 'runEveryXXXMinutes',
                headerName: intl.formatMessage(messages.runEveryXXXMinutes),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.runEveryXXXMinutes} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('runEveryXXXMinutes', hiddenColumns)
            },
            {
                field: 'lastExecuted',
                headerName: intl.formatMessage(messages.lastExecuted),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <NullableFormattedDate value={applyRowFn(params)?.lastExecuted} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('lastExecuted', hiddenColumns)
            },
            {
                field: 'lastFinished',
                headerName: intl.formatMessage(messages.lastFinished),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <NullableFormattedDate value={applyRowFn(params)?.lastFinished} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('lastFinished', hiddenColumns)
            },
            {
                field: 'stillRunning',
                headerName: intl.formatMessage(messages.stillRunning),
                flex: 0.3,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.stillRunning?.toString()} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('stillRunning', hiddenColumns)
            },
            {
                field: 'jobDescription',
                headerName: intl.formatMessage(messages.jobDescription),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.jobDescription} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('jobDescription', hiddenColumns)
            },
            {
                field: 'c_ErrorMessageForDisplay',
                headerName: intl.formatMessage(messages.errorMessage),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.c_ErrorMessageForDisplay} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('c_ErrorMessageForDisplay', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ReportCustomerTermsList;
