import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import CapriScheduledJob from '../../../interfaces/output/capriScheduledJob';
import CapriScheduledJobStep1Validation from './validations/capriScheduledJobStep1Validation';
import { isCreating, isUpdating, isLoadingById } from '../../../reducers/scheduledJobReducer';
import { FormattedMessage } from 'react-intl';
interface ICapriScheduledJobStep1Props {
    scheduledJob: CapriScheduledJob;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    PROCEDURE_NAME: 'procedureName',
    RUN_TO_DAY_IN_MONTH: 'runToDayInMonth',
    RUN_FROM_DAY_IN_MONTH: 'runFromDayInMonth',
    RUN_EVERY_XXX_MINUTES: 'runEveryXXXMinutes',
    RUN_FROM_HOURS: 'runFromHours',
    RUN_TO_HOURS: 'runToHours'

};

const messages = {
    runOnMonday: LanguageUtils.createMessage('Run on monday'),
    runOnTuesday: LanguageUtils.createMessage('Run on tuesday'),
    runOnWednesday: LanguageUtils.createMessage('Run on wednesday'),
    runOnThursday: LanguageUtils.createMessage('Run on thursday'),
    runOnFriday: LanguageUtils.createMessage('Run on friday'),
    runOnSaturday: LanguageUtils.createMessage('Run on saturday'),
    runOnSunday: LanguageUtils.createMessage('Run on sunday'),
    runFromDayInMonth: LanguageUtils.createMessage('Run from day in month'),
    runToDayInMonth: LanguageUtils.createMessage('Run to day in month'),
    runFromHours: LanguageUtils.createMessage('Run from hours'),
    runToHours: LanguageUtils.createMessage('Run to hours'),
    runEveryXXXMinutes: LanguageUtils.createMessage('Run every xxx minutes'),
    stillRunning: LanguageUtils.createMessage('Still running'),
    jobDescription: LanguageUtils.createMessage('Job description'),
    procedureName: LanguageUtils.createMessage('Procedure name'),
    jobEnabled: LanguageUtils.createMessage('Active')
};

const CapriScheduledJobFormStep1 = forwardRef((props: ICapriScheduledJobStep1Props, ref: any): JSX.Element => {
    const { scheduledJob, onChange, showValidationError } = props;
    const isLoadingObjects = [useSelector(isCreating), useSelector(isLoadingById), useSelector(isUpdating)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.PROCEDURE_NAME: {
                isValid = CapriScheduledJobStep1Validation.validateInputString(scheduledJob.procedureName);
                break;
            }
            case ATTRIBUTES.RUN_TO_DAY_IN_MONTH: {
                isValid = CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runToDayInMonth, 1, 31);
                break;
            }
            case ATTRIBUTES.RUN_FROM_DAY_IN_MONTH: {
                isValid = CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runFromDayInMonth, 1, 31);
                break;
            }
            case ATTRIBUTES.RUN_EVERY_XXX_MINUTES: {
                isValid = CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runEveryXXXMinutes, 1, 1440);
                break;
            }
            case ATTRIBUTES.RUN_FROM_HOURS: {
                isValid = CapriScheduledJobStep1Validation.validClock(scheduledJob.runFromHours);
                break;
            }
            case ATTRIBUTES.RUN_TO_HOURS: {
                isValid = CapriScheduledJobStep1Validation.validClock(scheduledJob.runToHours);
                break;
            }
        }

        return !isValid;
    };

    // const handleChange = (attribute: string, value: any) => {

    //     const valuetime = value;
    //     onChange(attribute, valuetime);
    // };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="procedureName"
                        label={messages.procedureName}
                        value={scheduledJob.procedureName}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.PROCEDURE_NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnMonday}
                                onChange={(e: any, value: boolean) => onChange('runOnMonday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnMonday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnTuesday}
                                onChange={(e: any, value: boolean) => onChange('runOnTuesday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnTuesday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnWednesday}
                                onChange={(e: any, value: boolean) => onChange('runOnWednesday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnWednesday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnThursday}
                                onChange={(e: any, value: boolean) => onChange('runOnThursday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnThursday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnFriday}
                                onChange={(e: any, value: boolean) => onChange('runOnFriday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnFriday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnSaturday}
                                onChange={(e: any, value: boolean) => onChange('runOnSaturday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnSaturday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.runOnSunday}
                                onChange={(e: any, value: boolean) => onChange('runOnSunday', value)}
                            />}
                            label={<FormattedMessage {...messages.runOnSunday} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="runFromDayInMonth"
                        label={messages.runFromDayInMonth}
                        value={scheduledJob.runFromDayInMonth}
                        onChange={onChange}
                        inputProps={{
                            min: 1,
                            max: 31
                        }}
                        error={setValidationState(ATTRIBUTES.RUN_FROM_DAY_IN_MONTH)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<any>
                        type="number"
                        name="runToDayInMonth"
                        label={messages.runToDayInMonth}
                        value={scheduledJob.runToDayInMonth}
                        onChange={onChange}
                        inputProps={{
                            min: 1,
                            max: 31
                        }}
                        error={setValidationState(ATTRIBUTES.RUN_TO_DAY_IN_MONTH)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<any>
                        type="string"
                        name="runFromHours"
                        label={messages.runFromHours}
                        value={scheduledJob.runFromHours}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.RUN_FROM_HOURS)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<any>
                        type="string"
                        name="runToHours"
                        label={messages.runToHours}
                        value={scheduledJob.runToHours}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.RUN_TO_HOURS)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="runEveryXXXMinutes"
                        label={messages.runEveryXXXMinutes}
                        value={scheduledJob.runEveryXXXMinutes}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.RUN_EVERY_XXX_MINUTES)}
                        inputProps={{
                            min: 1,
                            max: 1440
                        }}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.stillRunning}
                                onChange={(e: any, value: boolean) => onChange('stillRunning', value)}
                            />}
                            label={<FormattedMessage {...messages.stillRunning} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!scheduledJob.jobEnabled}
                                onChange={(e: any, value: boolean) => onChange('jobEnabled', value)}
                            />}
                            label={<FormattedMessage {...messages.jobEnabled} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="jobDescription"
                        label={messages.jobDescription}
                        value={scheduledJob.jobDescription}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader >
    );
});

export default CapriScheduledJobFormStep1;