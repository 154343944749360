import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import CustomStringLink from '../../common/customStringLink';
import UrlConstants from '../../../constants/UrlConstants';
import NumericFormat from 'react-number-format';
import CustomLink from '../../common/customLink';

interface ReportHeadersListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    month: LanguageUtils.createMessage('Month'),
    year: LanguageUtils.createMessage('Year'),
    controlStatus: LanguageUtils.createMessage('Control status'),
    amount: LanguageUtils.createMessage('Amount'),
    totalPrice: LanguageUtils.createMessage('Total price')
};

const ReportHeadersList = forwardRef((props: ReportHeadersListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);
    
    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'departureYear',
                headerName: `${intl.formatMessage(messages.year)}`,
                flex: 0.5,
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.departureYear}
                        link={`/#/${UrlConstants.REPORT_LINES}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('departureYear', hiddenColumns)
            },
            {
                field: 'departureMonth',
                headerName: intl.formatMessage(messages.month),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.departureMonth}
                        link={`/#/${UrlConstants.REPORT_LINES}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('departureMonth', hiddenColumns)
            },
            {
                field: 'controlStatus_id',
                headerName: intl.formatMessage(messages.controlStatus),
                flex: 1.5,
                align: 'right',
                headerAlign: 'right',
                sortable: false,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.controlStatus?.status}
                        link={`/#/${UrlConstants.REPORT_LINES}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('controlStatus_id', hiddenColumns)
            },
            {
                field: 'amount',
                headerName: intl.formatMessage(messages.amount),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.amount}
                        link={`/#/${UrlConstants.REPORT_LINES}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
            },
            {
                field: 'totalPrice',
                headerName: intl.formatMessage(messages.totalPrice),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) =>
                    <CustomLink align={'right'} link={`/#/${UrlConstants.REPORT_LINES}/${applyRowFn(param)?.id}`}>
                        <NumericFormat value={applyRowFn(param)?.totalPrice} displayType={'text'} thousandSeparator={true} />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('totalPrice', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.amount + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ReportHeadersList;
