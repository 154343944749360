import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import KickbackAgreementFilters from '../../../interfaces/filters/kickbackAgreementFilters';
import { exportKickbackAgreementPostenCSV, fetchKickBackPosten, getKickBackPosten, isLoadingKickBackPosten, resetKickBackPosten } from '../../../reducers/reportsReducer';
import KickbackAgreementFilterComponent from './kickbackAgreementFilter';
import UrlConstants from '../../../constants/UrlConstants';
import KickBackPosten from '../../../interfaces/output/kickBackPosten';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import PageUtils from '../../../utils/pageUtils';
import KickBackAgreementList from './kickBackAgreementList';
import { getUserDetails } from '../../../reducers/userReducer';
import UserOut from '../../../interfaces/output/userOut';
import { hasRoles } from '../../../utils/roleUtils';
import RoleConstants from '../../../constants/roleConstants';

interface IKickbackAgreementCSVProps {
    classes: any;
    exportKickbackAgreementPostenCSV: any;
    isLoading: boolean;
    onChange: () => void;
    resetKickBackPosten: () => void;
    fetchKickBackPosten: any;
    fetch: any;
    kickBackPostens: Pagination<KickBackPosten>;
    user: UserOut;
}

interface IKickBackAgreementCSVState {
    filters: KickbackAgreementFilters;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    kickBackAgreement: LanguageUtils.createMessage('Kickback Agreement'),
    downloadCSV: LanguageUtils.createMessage('Download CSV')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exportKickbackAgreementPostenCSV: (filters: KickbackAgreementFilters) => dispatch(exportKickbackAgreementPostenCSV({
        filters
    })),
    fetchKickBackPosten: (paging: Paging, filters: KickbackAgreementFilters) => dispatch(fetchKickBackPosten({
        paging,
        filters
    })),
    resetKickBackPosten: () => dispatch(resetKickBackPosten())
});

const mapStoreToProps = (store: RootState) => ({
    isLoading: isLoadingKickBackPosten(store),
    kickBackPostens: getKickBackPosten(store),
    user: getUserDetails(store)
});

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class KickbackAgreementCSV extends Component<IKickbackAgreementCSVProps, IKickBackAgreementCSVState> {
    kickBackPostenListRef: any;
    constructor(props: IKickbackAgreementCSVProps) {
        super(props);

        this.state = {
            filters: {} as KickbackAgreementFilters,
            menuItem: {
                id: 1,
                name: messages.kickBackAgreement,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            },
            paging: PageUtils.getDefaultPaging([{
                field: 'id',
                sort: 'asc'
            } as GridSortItem]),
            sortModel: [{
                field: 'id',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchKickBackPosten } = this.props;
        const { paging, filters } = this.state;

        fetchKickBackPosten(paging, filters);
    }

    componentWillUnmount() {
        const { resetKickBackPosten } = this.props;
        resetKickBackPosten();
    }

    exportCSV = () => {
        const { exportKickbackAgreementPostenCSV } = this.props;
        const { filters } = this.state;

        exportKickbackAgreementPostenCSV(filters);
    }

    applyFilters = () => {
        const { fetchKickBackPosten } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchKickBackPosten(newPagination, filters);

        if(this.kickBackPostenListRef !== null && this.kickBackPostenListRef !== undefined &&
            this.kickBackPostenListRef.current !== null && this.kickBackPostenListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.kickBackPostenListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as KickbackAgreementFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchKickBackPosten } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
        } as KickbackAgreementFilters;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchKickBackPosten(newPagination, newFilters);

        if(this.kickBackPostenListRef !== null && this.kickBackPostenListRef !== undefined &&
            this.kickBackPostenListRef.current !== null && this.kickBackPostenListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.kickBackPostenListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchKickBackPosten } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchKickBackPosten(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoading, kickBackPostens, fetchKickBackPosten, user } = this.props;
        const { filters, menuItem, paging, columnVisibilityModel } = this.state;
        const isAdmin = hasRoles([RoleConstants.Roles.ROLE_ADMIN], user.roles);
        
        return (
            <MainLayout menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
            >
                <Container maxWidth="xl" className={classes.container}>
                    {isAdmin &&
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <KickbackAgreementFilterComponent
                                        actions={[{
                                            text: messages.downloadCSV,
                                            icon: 'download',
                                            onClick: this.exportCSV
                                        }]}
                                        applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                    <KickBackAgreementList
                                        rows={kickBackPostens} isLoading={isLoading}
                                        ref={this.kickBackPostenListRef}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...paging,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            fetchKickBackPosten(newPage, filters);
                                            this.setState({ paging: newPage });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </Paper>
                            </Grid>
                        </Grid>}
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(KickbackAgreementCSV));