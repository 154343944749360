import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import CapriScheduledJob from '../interfaces/output/capriScheduledJob';
import CapriScheduledJobFilter from '../interfaces/filters/capriScheduledJobFilter';
import CapriScheduledJobLogFilter from '../interfaces/filters/capriScheduledJobLogFilter';
import CapriScheduledJobLog from '../interfaces/output/capriScheduledJobLog';
import moment from 'moment';

export default class CapriScheduledJobApi {
    static fetchCapriScheduledJobLogs = async(paging: Paging, filters?: CapriScheduledJobLogFilter): Promise<Pagination<CapriScheduledJobLog>> => {
        const formattedFilters = {
            ...filters,
            procedureStarted: filters?.procedureStarted ? moment(filters?.procedureStarted).toISOString() : undefined,
            procedureEnded: filters?.procedureEnded ? moment(filters?.procedureEnded).toISOString() : undefined
        };
        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/caprischeduledjoblog');
        const { data } = await http.get<Pagination<CapriScheduledJobLog>>(url);

        return data;
    };

    static fetchCapriScheduledJobs = async(paging: Paging, filters?: CapriScheduledJobFilter): Promise<Pagination<CapriScheduledJob>> => {
        const formattedFilters = {
            ...filters,
            lastExecuted: filters?.lastExecuted ? moment(filters?.lastExecuted).toISOString() : undefined,
            lastFinished: filters?.lastFinished ? moment(filters?.lastFinished).toISOString() : undefined
        };
        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/caprischeduledjob');
        const { data } = await http.get<Pagination<CapriScheduledJob>>(url);

        return data;
    };

    static fetchCapriScheduledJobById = async(id: string): Promise<CapriScheduledJob> => {
        const { data } = await http.get<CapriScheduledJob>(`/caprischeduledjob/${id}`);

        return data;
    };

    static createCapriScheduledJob = async(scheduledJob: CapriScheduledJob): Promise<CapriScheduledJob> => {
        const newScheduledJob = {
            runOnMonday: scheduledJob.runOnMonday,
            runOnTuesday: scheduledJob.runOnTuesday,
            runOnWednesday: scheduledJob.runOnWednesday,
            runOnThursday: scheduledJob.runOnThursday,
            runOnFriday: scheduledJob.runOnFriday,
            runOnSaturday: scheduledJob.runOnSaturday,
            runOnSunday: scheduledJob.runOnSunday,
            runFromDayInMonth: scheduledJob.runFromDayInMonth,
            runToDayInMonth: scheduledJob.runToDayInMonth,
            runFromHours: scheduledJob.runFromHours,
            runToHours: scheduledJob.runToHours,
            runEveryXXXMinutes: scheduledJob.runEveryXXXMinutes,
            lastExecuted: scheduledJob.lastExecuted,
            lastFinished: scheduledJob.lastFinished,
            stillRunning: scheduledJob.stillRunning ?? false,
            jobDescription: scheduledJob.jobDescription,
            procedureName: scheduledJob.procedureName,
            jobEnabled: scheduledJob.jobEnabled ?? false,
            errorMessageBackend: scheduledJob.errorMessageBackend,
            errorMessageDB: scheduledJob.errorMessageDB,
            c_ErrorMessageForDisplay: scheduledJob.c_ErrorMessageForDisplay
        };

        const { data } = await http.post<any, CapriScheduledJob>('caprischeduledjob', newScheduledJob);

        return data;
    };

    static updateCapriScheduledJob = async(scheduledJob: CapriScheduledJob): Promise<CapriScheduledJob> => {
        const newScheduledJob = {
            runOnMonday: scheduledJob.runOnMonday,
            runOnTuesday: scheduledJob.runOnTuesday,
            runOnWednesday: scheduledJob.runOnWednesday,
            runOnThursday: scheduledJob.runOnThursday,
            runOnFriday: scheduledJob.runOnFriday,
            runOnSaturday: scheduledJob.runOnSaturday,
            runOnSunday: scheduledJob.runOnSunday,
            runFromDayInMonth: scheduledJob.runFromDayInMonth,
            runToDayInMonth: scheduledJob.runToDayInMonth,
            runFromHours: scheduledJob.runFromHours,
            runToHours: scheduledJob.runToHours,
            runEveryXXXMinutes: scheduledJob.runEveryXXXMinutes,
            stillRunning: scheduledJob.stillRunning ?? false,
            jobDescription: scheduledJob.jobDescription,
            procedureName: scheduledJob.procedureName,
            lastExecuted: scheduledJob.lastExecuted,
            lastFinished: scheduledJob.lastFinished,
            jobEnabled: scheduledJob.jobEnabled ?? false,
            errorMessageBackend: scheduledJob.errorMessageBackend ?? '',
            errorMessageDB: scheduledJob.errorMessageDB ?? '',
            c_ErrorMessageForDisplay: scheduledJob.c_ErrorMessageForDisplay
        };

        const { data } = await http.put<any, CapriScheduledJob>(`caprischeduledjob/${scheduledJob.id}`, newScheduledJob);

        return data;
    };

    static deleteCapriScheduledJobById = async(id: number): Promise<CapriScheduledJob> => {
        const { data } = await http.delete<CapriScheduledJob>(`/caprischeduledjob/${id}`);

        return data;
    };

    static deactivateCapriScheduledJobById = async(scheduledJob: CapriScheduledJob): Promise<CapriScheduledJob> => {
        const { data } = await http.put<any, CapriScheduledJob>(`caprischeduledjob/${scheduledJob?.id}`);

        return data;
    };
}
