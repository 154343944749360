import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import CapriScheduledJob from '../interfaces/output/capriScheduledJob';
import { RootState } from '../setup';
import CapriScheduledJobFilter from '../interfaces/filters/capriScheduledJobFilter';
import CapriScheduledJobLog from '../interfaces/output/capriScheduledJobLog';
import CapriScheduledJobLogFilter from '../interfaces/filters/capriScheduledJobLogFilter';

export type ScheduledJobState = {
    scheduledJobLogs: Pagination<CapriScheduledJobLog>;
    isLoading: boolean;
    scheduledJobs: Pagination<CapriScheduledJob>;
    isLoadingScheduledJobLog: boolean;
    error: HttpErrorResponse | undefined;
    scheduledJob: CapriScheduledJob;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoadingById: boolean;
    isDeactivate: boolean;
};

const initialState: ScheduledJobState = {
    scheduledJobLogs: { content: [] as Array<CapriScheduledJobLog> } as Pagination<CapriScheduledJobLog>,
    scheduledJobs: { content: [] as Array<CapriScheduledJob> } as Pagination<CapriScheduledJob>,
    isLoading: false,
    error: undefined,
    isLoadingScheduledJobLog: false,
    scheduledJob: {} as CapriScheduledJob,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isLoadingById: false,
    isDeactivate: false
};

export const scheduledJobSlice = createSlice({
    name: 'scheduledJob',

    initialState,
    reducers: {
        fetchScheduledJobs: (state: ScheduledJobState, _action: PayloadAction<{ paging: Paging, filters?: CapriScheduledJobFilter }>) => {
            state.isLoading = true;
        },
        fetchScheduledJobsSuccess: (state: ScheduledJobState, action: PayloadAction<Pagination<CapriScheduledJob>>) => {
            state.isLoading = false;
            state.scheduledJobs = action.payload;
        },
        fetchScheduledJobsError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        resetScheduledJob: (state: ScheduledJobState) => {
            state.scheduledJobs = initialState.scheduledJobs;
        },
        fetchScheduledJobLogs: (state: ScheduledJobState, _action: PayloadAction<{ paging: Paging, filters?: CapriScheduledJobLogFilter }>) => {
            state.isLoadingScheduledJobLog = true;
        },
        fetchScheduledJobLogsSuccess: (state: ScheduledJobState, action: PayloadAction<Pagination<CapriScheduledJobLog>>) => {
            state.isLoadingScheduledJobLog = false;
            state.scheduledJobLogs = action.payload;
        },
        fetchScheduledJobLogsError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingScheduledJobLog = false;
            state.error = action.payload;
        },
        resetScheduledJobLogs: (state: ScheduledJobState) => {
            state.scheduledJobLogs = initialState.scheduledJobLogs;
        },
        fetchCapriScheduledJobById: (state: ScheduledJobState, _action: PayloadAction<number>) => {
            state.isLoadingById = true;
        },
        fetchCapriScheduledJobByIdSuccess: (state: ScheduledJobState, action: PayloadAction<CapriScheduledJob>) => {
            state.isLoadingById = false;
            state.scheduledJob = action.payload;
        },
        fetchCapriScheduledJobByIdError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingById = false;
        },
        createCapriScheduledJob: (state: ScheduledJobState, _action: PayloadAction<CapriScheduledJob>) => {
            state.isCreating = true;
        },
        createCapriScheduledJobSuccess: (state: ScheduledJobState, action: PayloadAction<CapriScheduledJob>) => {
            state.isCreating = false;
            state.scheduledJob = action.payload;
        },
        createCapriScheduledJobError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateCapriScheduledJob: (state: ScheduledJobState, _action: PayloadAction<CapriScheduledJob>) => {
            state.isUpdating = true;
        },
        updateCapriScheduledJobSuccess: (state: ScheduledJobState, action: PayloadAction<CapriScheduledJob>) => {
            state.isUpdating = false;
            state.scheduledJob = action.payload;
        },
        updateCapriScheduledJobError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteCapriScheduledJob: (state: ScheduledJobState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deleteCapriScheduledJobSuccess: (state: ScheduledJobState, action: PayloadAction<CapriScheduledJob>) => {
            state.isDeleting = false;
            state.scheduledJob = action.payload;
        },
        deleteCapriScheduledJobError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        deactivateCapriScheduledJob: (state: ScheduledJobState, _action: PayloadAction<CapriScheduledJob>) => {
            state.isDeactivate = true;
        },
        deactivateCapriScheduledJobSuccess: (state: ScheduledJobState, action: PayloadAction<CapriScheduledJob>) => {
            state.isDeactivate = false;
            state.scheduledJob = action.payload;
        },
        deactivateCapriScheduledJobError: (state: ScheduledJobState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeactivate = false;
            state.error = action.payload;
        }
    }
});

export const { fetchScheduledJobs, fetchScheduledJobsSuccess, fetchScheduledJobsError, fetchScheduledJobLogs,
    fetchScheduledJobLogsError, fetchScheduledJobLogsSuccess, resetScheduledJob, resetScheduledJobLogs,
    fetchCapriScheduledJobById, fetchCapriScheduledJobByIdError, fetchCapriScheduledJobByIdSuccess,
    createCapriScheduledJob, createCapriScheduledJobError, createCapriScheduledJobSuccess, updateCapriScheduledJob, updateCapriScheduledJobError,
    updateCapriScheduledJobSuccess, deleteCapriScheduledJob, deleteCapriScheduledJobError, deleteCapriScheduledJobSuccess,
    deactivateCapriScheduledJob, deactivateCapriScheduledJobError, deactivateCapriScheduledJobSuccess } = scheduledJobSlice.actions;
export const isLoadingScheduledJobs = (state: RootState): boolean => state.scheduledJobReducer.isLoading;
export const getScheduledJobs = (state: RootState): Pagination<CapriScheduledJob> => state.scheduledJobReducer.scheduledJobs;
export const getScheduledJobLogs = (state: RootState): Pagination<CapriScheduledJobLog> => state.scheduledJobReducer.scheduledJobLogs;
export const isLoadingScheduledJobLogs = (state: RootState): boolean => state.scheduledJobReducer.isLoadingScheduledJobLog;
export const getScheduledJob = (state: RootState): CapriScheduledJob => state.scheduledJobReducer.scheduledJob;
export const isLoadingById = (state: RootState): boolean => state.scheduledJobReducer.isLoadingById;
export const isCreating = (state: RootState): boolean => state.scheduledJobReducer.isCreating;
export const isUpdating = (state: RootState): boolean => state.scheduledJobReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.scheduledJobReducer.isDeleting;
export const isDeactivate = (state: RootState): boolean => state.scheduledJobReducer.isDeactivate;

export default scheduledJobSlice.reducer;