import { http } from '../utils/http';
import CancellationType from '../interfaces/output/cancellationType';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import Pagination from '../interfaces/common/pagination';

export default class CancellationTypeApi {

    static fetchCancellationTypes = async(paging: Paging): Promise<Pagination<CancellationType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/cancellationtypes');

        const { data } = await http.get<Pagination<CancellationType>>(url);

        return data;
    };

}
