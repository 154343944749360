import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import TruncatedStringTooltip from '../../common/truncatedStringTooltip';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import NullableFormattedDateWithoutUtc from '../../common/nullableFormattedDateWithoutUtc';

interface ReportCustomerTermsListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    validFrom: LanguageUtils.createMessage('valid from'),
    validTo: LanguageUtils.createMessage('valid to'),
    customerName: LanguageUtils.createMessage('Customer Name'),
    customerTermDescription: LanguageUtils.createMessage('Customer term description')
};

const ReportCustomerTermsList = forwardRef((props: ReportCustomerTermsListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'validFrom',
                headerName: intl.formatMessage(messages.validFrom),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <NullableFormattedDateWithoutUtc value={cellValues.row?.validFrom} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFrom', hiddenColumns)
            },
            {
                field: 'validTo',
                headerName: `${intl.formatMessage(messages.validTo)}`,
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <NullableFormattedDate value={cellValues.row?.validTo} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validTo', hiddenColumns)
            },
            {
                field: 'customer_name',
                headerName: intl.formatMessage(messages.customerName),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.customer?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('customer_name', hiddenColumns)
            },
            {
                field: 'customerTermDescription',
                headerName: intl.formatMessage(messages.customerTermDescription),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.customerTermDescription} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('customerTermDescription', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.customer?.name + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ReportCustomerTermsList;
