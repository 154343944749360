import inputLengthConstants from '../constants/inputLengthConstants';

class NumberUtils {
    static isNullOrWhitespace(input: number | undefined): boolean {
        if(input === null) {
            return true;
        }
        if(input === undefined || input.toString().trim().length === 0) {
            return true;
        }

        const number = Number(input);
        const validationResult = !isNaN(number) ? !(number >= 0 && number <= inputLengthConstants.StringMax) : true;

        return validationResult;
    }

    static numberIsInInterval(inputNumber: number| undefined, min: number, max: number): boolean {
        const number = Number(inputNumber);
        if(number !== null && number !== undefined) {

            if(number >= min && number <= max) {

                return true;
            }
            else {

                return false;
            }
        }

        return true;
    }
}

export default NumberUtils;
