import React from 'react';
import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import Action from '../../../interfaces/common/action';
import ActionRenderer from '../../common/actionRenderer';
import ReportCustomerTermsFilters from '../../../interfaces/filters/reportCustomerTermsFilters';
import Customer from '../../../interfaces/output/customer';
import { fetchCustomers, getCustomers, isLoadingCustomers, resetCustomers } from '../../../reducers/customerReducer';
import { useDispatch, useSelector } from 'react-redux';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import CustomerFilters from '../../../interfaces/output/filters';
import PageUtils from '../../../utils/pageUtils';
import GenericTextField from '../../common/widgets/genericTextField';

interface IFilterProps {
    filters: ReportCustomerTermsFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    date: LanguageUtils.createMessage('Valid on this date'),
    customerTermDescription: LanguageUtils.createMessage('Customer term description'),
    customer: LanguageUtils.createMessage('Customer name'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function ReportCustomerTermsFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [customerValue, setCustomerValue] = React.useState<any>(null);
    const paging = PageUtils.getMaxPaging();
    const customers = useSelector(getCustomers).content;
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const customerFilters = {
        name: inputValue
    } as CustomerFilters;

    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const onChangeCustomer = (attribute: string, value: any) => {

        onChange(attribute, value?.name);
        setCustomerValue(value);
    };

    const setDefaultStateReportCustomerTerms = () => {
        setInputValue('');
        onChangeCustomer('customerName', null);
        dispatch(resetCustomers());
        setDefaultState();
    };

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <GenericDatePicker
                            name="validOnThisDate"
                            label={messages.date}
                            value={filters?.validOnThisDate}
                            onChange={onChange}
                            type="now"
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            value={customerValue}
                            onChange={(e, newValue: any) => {
                                onChangeCustomer('customerName', newValue);
                            }}
                            id="controllable-states-demo"
                            getOptionLabel={(option: Customer) => option.name}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue: any, reason) => {
                                setInputValue(newInputValue);
                                if(newInputValue.length > 2) {
                                    dispatch(resetCustomers());
                                    dispatch(fetchCustomers({
                                        paging,
                                        filters: {
                                            ...customerFilters,
                                            name: newInputValue
                                        },
                                        throttle: true
                                    }));
                                }
                                if(reason === 'clear' && event.type === 'click') {
                                    dispatch(resetCustomers());
                                }
                            }}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="customerTermDescription"
                                label={messages.customerTermDescription}
                                onChange={onChange}
                                value={filters.customerTermDescription}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultStateReportCustomerTerms}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}