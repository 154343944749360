import React from 'react';
import TruncatedStringTooltip from './truncatedStringTooltip';
import CustomLink from './customLink';

interface ICustomStringLink {
    value: any;
    link: any
    disabled?: any;
    shouldUseHistory?: boolean;
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify'
}

export default function CustomStringLink(props: ICustomStringLink): JSX.Element {
    const { value, link, disabled, shouldUseHistory, align } = props;

    return (
        <CustomLink link={link} disabled={disabled} shouldUseHistory={shouldUseHistory} align={align}>
            <TruncatedStringTooltip value={value} />
        </CustomLink>
    );
}