import { http } from '../utils/http';
import UnitType from '../interfaces/output/unitType';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class UnitTypeApi {
    static fetchUnitType = async(unittypeid: string): Promise<UnitType> => {
        const { data } = await http.get<UnitType>(`unittypes/${unittypeid}`);

        return data;
    };

    static fetchUnitTypes = async(paging: Paging): Promise<Pagination<UnitType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/unittypes');
        const { data } = await http.get<Pagination<UnitType>>(url);
        
        return data;
    };
}
