import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import ReportLine from '../../../interfaces/output/ReportLine';
import { fetchReportLineById, getReportLine, isLoadingReportLine, resetReportLine } from '../../../reducers/reportsReducer';
import LanguageUtils from '../../../utils/LanguageUtils';
import { RootState } from '../../../setup';
import UrlConstants from '../../../constants/UrlConstants';
import PageUtils from '../../../utils/pageUtils';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import MainLayout from '../../common/widgets/mainLayout';
import { ObjectType } from '../../../constants/constants';
import ViewReportLine from './viewReportLine';

interface IReportLineDetailsProps {
    classes: any;
    theme: Theme;
    fetchReportLineById: any;
    reportLine: ReportLine;
    match: any;
    history: any;
    location: any;
    isLoadingReportLineByid: boolean;
    resetReportLine: () => void;
}

interface IReportLineDetailsState {
    reportLine: ReportLine;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchReportLineById: (reportLineId: any) => dispatch(fetchReportLineById(reportLineId)),
    resetReportLine: () => dispatch(resetReportLine())
});

const messages = {
    properties: LanguageUtils.createMessage('Properties'),
    reportLine: LanguageUtils.createMessage('Report line')
};

const mapStoreToProps = (store: RootState) => {
    return {
        reportLine: getReportLine(store),
        isLoading: isLoadingReportLine(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class ReportLineDetails extends Component<IReportLineDetailsProps, IReportLineDetailsState> {
    constructor(props: IReportLineDetailsProps) {
        super(props);

        this.state = {
            reportLine: {} as ReportLine,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.REPORT_LINE}/${props.match.params.reportLineId}`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.REPORT_LINE}/${props.match.params.reportLineId}`, props.location.pathname)
            }
            ]),
            serverProductPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchReportLineById } = this.props;
        const { reportLineId } = this.props.match.params;

        fetchReportLineById(reportLineId);
    }

    componentDidUpdate(prevProps: IReportLineDetailsProps) {
        const { reportLine } = this.props;

        if(reportLine !== prevProps.reportLine) {
            this.setState({ reportLine });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            });
        }
    }

    componentWillUnmount() {
        resetReportLine();
    }

    getSelectedMenuItem = (state: IReportLineDetailsState) => state.menuItems.find(item => item.isSelected);

    // handleMenuItems = () => {
    //     const { menuItems } = this.state;
    //     const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
    //     const selectedMenuItemId = selectedMenuItem?.id;
    //     const { reportLineId } = this.props.match.params;

    // switch (selectedMenuItemId) {
    //     case 1: { //details
    //         return [
    //             {
    //                 href: `${UrlConstants.REPORTLINE}/${reportLineId}/edit`,
    //                 icon: 'edit',
    //                 text: messages.edit
    //             }
    //         ];
    //     }

    //     default: {
    //         return [];
    //     }
    // }
    //}

    render() {
        const { classes, isLoadingReportLineByid } = this.props;
        const { reportLine, menuItems } = this.state;

        return (
            <MainLayout
                isLoading={isLoadingReportLineByid}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.OrderUnit}
                menuItem={{
                    id: 1,
                    subname: messages.reportLine,
                    value: reportLine?.id,
                    icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" />
                } as IMenuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.REPORT_LINE}/:reportLineId/`}>
                            <ViewReportLine reportLine={reportLine} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ReportLineDetails));