import React, { useEffect } from 'react';
import Agreement from '../../../interfaces/output/agreement';
import FontAwesomeIcon from '../../../utils/FontAwesomeIcons';
import NullableFormattedDate from '../nullableFormattedDate';
import If from './if';
import PriceList from '../../../interfaces/output/priceList';
import Customer from '../../../interfaces/output/customer';
import Product from '../../../interfaces/output/product';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgreementById, getAgreement, isLoadingAgreement } from '../../../reducers/agreementsReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import { fetchCustomerById, getCustomer, isLoadingCustomer } from '../../../reducers/customerReducer';
import { fetchProductById, getProduct, isLoadingProduct } from '../../../reducers/productReducer';

interface IStepperAdditionalInformationProps {
    priceList?: PriceList;
    agreement?: Agreement;
    customerId?: number;
    productId?: number;
    agreementId?: number;
    agreementVersionId?: number;
}

export default function StepperAdditionalInformation(props: IStepperAdditionalInformationProps): JSX.Element {
    const { agreement, priceList, customerId, productId, agreementId, agreementVersionId } = props;
    const dispatch = useDispatch();
    const [newAgreement, setAgreement] = React.useState<Agreement>();
    const propsAgreement = useSelector(getAgreement);
    const propsIsLoadingAgreement = useSelector(isLoadingAgreement);
    const prevIsLoadingAgreement = WorkflowUtils.usePrevious<boolean>(propsIsLoadingAgreement);
    const [customer, setCustomer] = React.useState<Customer>({
        id: customerId
    } as Customer);
    const propsCustomer = useSelector(getCustomer);
    const propsIsLoadingCustomer = useSelector(isLoadingCustomer);
    const prevIsLoadingCustomer = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCustomer);
    const [product, setProduct] = React.useState<Product>();
    const propsIsLoadingProduct = useSelector(isLoadingProduct);
    const prevIsLoadingProduct = WorkflowUtils.usePrevious<boolean>(propsIsLoadingProduct);
    const propsProduct = useSelector(getProduct);

    useEffect(() => {
        if(agreementId) {
            dispatch(fetchAgreementById({
                id: agreementId,
                agreementVersionId
            }));
        }
        if(customerId) {
            dispatch(fetchCustomerById(customerId));
        }
        if(productId) {
            dispatch(fetchProductById(productId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(!propsIsLoadingAgreement && prevIsLoadingAgreement) {
            setAgreement(propsAgreement);
        }

    }, [propsIsLoadingAgreement]);

    useEffect(() => {
        if(!propsIsLoadingCustomer && prevIsLoadingCustomer) {
            setCustomer(propsCustomer);
        }

    }, [propsIsLoadingCustomer]);

    useEffect(() => {
        if(!propsIsLoadingProduct && prevIsLoadingProduct) {
            setProduct(propsProduct);
        }

    }, [propsIsLoadingProduct]);

    return (<>
        <If isTrue={!!agreement?.name}>
            <strong>
                <span>{agreement?.name}</span>
                {!!agreement?.version?.versionNumber && (<>
                    <span> <FontAwesomeIcon icon="layer-group" size="1x" transform="shrink-2" style={{ marginLeft: '5px' }} />
                        <span> {agreement?.version?.versionNumber ?? '-'} </span>
                    </span>
                    <span> <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" style={{ marginLeft: '5px' }} />
                        {agreement?.version?.validFromDate || agreement?.version?.validToDate ? (
                            <span style={{ marginLeft: '5px' }}>
                                <NullableFormattedDate value={agreement?.version?.validFromDate} /> -
                                {agreement?.version?.validToDate &&
                                    <> <NullableFormattedDate value={agreement?.version?.validToDate} /></>}
                            </span>
                        )
                            : '-'} </span>
                </>
                )}
            </strong>
        </If>
        <If isTrue={!!newAgreement?.name && !agreement?.name} >
            <strong>
                <span>{newAgreement?.name}</span>
                {!!newAgreement?.version?.versionNumber && agreementVersionId && (<>
                    <span> <FontAwesomeIcon icon="layer-group" size="1x" transform="shrink-2" style={{ marginLeft: '5px' }} />
                        <span> {newAgreement?.version?.versionNumber ?? '-'} </span>
                    </span>
                    <span> <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" style={{ marginLeft: '5px' }} />
                        {newAgreement?.version?.validFromDate || newAgreement?.version?.validToDate ? (
                            <span style={{ marginLeft: '5px' }}>
                                <NullableFormattedDate value={newAgreement?.version?.validFromDate} /> -
                                {newAgreement?.version?.validToDate &&
                                    <> <NullableFormattedDate value={newAgreement?.version?.validToDate} /></>}
                            </span>
                        )
                            : '-'} </span>
                </>
                )}
            </strong>
        </If>
        <If isTrue={!!priceList?.name}>
            <strong>
                <span>{priceList?.name}</span>
                {!!priceList?.version?.versionNumber && (<>
                    <span> <FontAwesomeIcon icon="layer-group" size="1x" transform="shrink-2" style={{ marginLeft: '5px' }} />
                        <span> {priceList?.version?.versionNumber ?? '-'} </span>
                    </span>
                    <span> <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" style={{ marginLeft: '5px' }} />
                        {priceList?.version?.validFromDate || priceList?.version?.validToDate ? (
                            <span style={{ marginLeft: '5px' }}>
                                <NullableFormattedDate value={priceList?.version?.validFromDate} /> -
                                {priceList?.version?.validToDate &&
                                    <> <NullableFormattedDate value={priceList?.version?.validToDate} /></>}
                            </span>
                        )
                            : '-'} </span>
                </>
                )}
            </strong>
        </If>
        <If isTrue={!!customer?.name}>
            <strong>
                <span>{customer?.name} - {customer?.gtsId}</span>
            </strong>
        </If>
        <If isTrue={!!product?.name}>
            <strong>
                <span>{product?.name}</span>
            </strong>
        </If>
    </>
    );
}
