import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import {
    createAgreementVersionSpecialPriceById, fetchAgreementVersionSpecialPriceById, getAgreementVersionSpecialPrice, isCreatingAgreementVersionSpecialPrice, isUpdatingAgreementSpecialPrice,
    updateAgreementVersionSpecialPriceById, isLoadingAgreementVersionSpecialPrice
} from '../../../reducers/agreementVersionSpecialPriceReducer';
import AgreementVersionSpecialPriceOut from '../../../interfaces/output/agreementVersionSpecialPriceOut';
import AgreementVersionSpecialPriceFormStep1 from './agreementVersionSpecialPriceFormStep1';
import AgreementVersionSpecialPriceStep1Validation from '../validations/AgreementVersionSpecialPriceStep1Validation';
import StepperAdditionalInformation from '../../common/widgets/stepperAdditionalInformation';

interface IAddAddAgreementVersionSpecialPriceFormProps {
    agreementVersionSpecialPriceId?: number;
    agreementVersionId?: number;
    agreementId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit special price'),
    add: LanguageUtils.createMessage('Add special price')
};

export default function AgreementVersionSpecialPriceForm(props: IAddAddAgreementVersionSpecialPriceFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { agreementVersionSpecialPriceId, agreementVersionId, steps, type, agreementId } = props;
    const [agreementVersionSpecialPrice, setAgreementVersionSpecialPrice] =
        React.useState<AgreementVersionSpecialPriceOut>({ agreementVersion: { id: agreementVersionId } } as AgreementVersionSpecialPriceOut);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsAddAgreementVersionSpecialPrice = useSelector(getAgreementVersionSpecialPrice);
    const propsIsUpdatingSpecialPrice = useSelector(isUpdatingAgreementSpecialPrice);
    const prevIsUpdatingSpecialPrice = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingSpecialPrice);
    const propsIsCreatingSpecialPrice = useSelector(isCreatingAgreementVersionSpecialPrice);
    const prevIsCreatingSpecialPrice = WorkflowUtils.usePrevious<boolean>(propsIsCreatingSpecialPrice);
    const propsIsLoadingAgreementVersionSpecialPrice = useSelector(isLoadingAgreementVersionSpecialPrice);
    const prevIsLoadingAgreementVersionSpecialPrice = WorkflowUtils.usePrevious<boolean>(propsIsLoadingAgreementVersionSpecialPrice);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingSpecialPrice && !propsIsUpdatingSpecialPrice) ||
                    (prevIsCreatingSpecialPrice && !propsIsCreatingSpecialPrice)) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsCreatingSpecialPrice, propsIsUpdatingSpecialPrice]);

    useEffect(() => {
        if(agreementVersionSpecialPriceId) {
            dispatch(fetchAgreementVersionSpecialPriceById(agreementVersionSpecialPriceId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingAgreementVersionSpecialPrice && !propsIsLoadingAgreementVersionSpecialPrice) {
            setAgreementVersionSpecialPrice(propsAddAgreementVersionSpecialPrice);
        }

    }, [propsIsLoadingAgreementVersionSpecialPrice]);

    const onChange = (attribute: string, value: any) => {
        const newAgreementVersionSpecialPrice = { ...agreementVersionSpecialPrice } as AgreementVersionSpecialPriceOut;

        (newAgreementVersionSpecialPrice as any)[attribute] = value;

        setAgreementVersionSpecialPrice(newAgreementVersionSpecialPrice);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <AgreementVersionSpecialPriceFormStep1 onChange={onChange} agreementVersionSpecialPrice={agreementVersionSpecialPrice} />,
            validationFn: () => AgreementVersionSpecialPriceStep1Validation.validateAgreementVersionSpecialPriceForm(agreementVersionSpecialPrice),
            onNext: () => {
                if(!agreementVersionSpecialPriceId) {
                    dispatch(createAgreementVersionSpecialPriceById(agreementVersionSpecialPrice));
                }
                else {
                    dispatch(updateAgreementVersionSpecialPriceById(agreementVersionSpecialPrice));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.AGREEMENTS}/${agreementId}/${agreementVersionId}/specialprice`;

            case FormType.Edit:
                return `/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${propsAddAgreementVersionSpecialPrice.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
            additionalDetails={<StepperAdditionalInformation agreementId={agreementId} agreementVersionId={agreementVersionId} />}
        />
    );
}
