import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import Pagination from '../../interfaces/common/pagination';
import CapriScheduledJob from '../../interfaces/output/capriScheduledJob';
import IMenuItem from '../../interfaces/common/menuItem';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { RootState } from '../../setup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageUtils from '../../utils/pageUtils';
import MainLayout from '../common/widgets/mainLayout';
import UrlConstants from '../../constants/UrlConstants';
import CapriScheduledJobFilter from '../../interfaces/filters/capriScheduledJobFilter';
import { fetchScheduledJobs, getScheduledJobs, isLoadingScheduledJobs, resetScheduledJob } from '../../reducers/scheduledJobReducer';
import CapriScheduledJobFilterComponent from './capriScheduledJobFilter';
import CapriScheduledJobList from './capriScheduledJobList';

interface IScheduledJobProps {
    classes: any;
    isLoading: boolean;
    onChange: () => void;
    resetSchduledJob: () => void;
    fetchScheduledJobs: any;
    scheduledJobs: Pagination<CapriScheduledJob>;
}

interface ICapriScheduledJobsState {
    filters: CapriScheduledJobFilter;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    scheduledJob: LanguageUtils.createMessage('Scheduled job'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchScheduledJobs: (paging: Paging, filters: CapriScheduledJobFilter) => dispatch(fetchScheduledJobs({
        paging,
        filters
    })),
    resetSchduledJob: () => dispatch(resetScheduledJob())
});

const mapStoreToProps = (store: RootState) => ({
    isLoading: isLoadingScheduledJobs(store),
    scheduledJobs: getScheduledJobs(store)
});

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class CapriSchduledJobComponent extends Component<IScheduledJobProps, ICapriScheduledJobsState> {
    capriSchduledJobListRef: any;
    constructor(props: IScheduledJobProps) {
        super(props);

        this.state = {
            filters: {} as CapriScheduledJobFilter,
            menuItem: {
                id: 1,
                name: messages.scheduledJob,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            },
            paging: PageUtils.getDefaultPaging([{
                field: 'procedureName',
                sort: 'asc'
            } as GridSortItem]),
            sortModel: [{
                field: 'procedureName',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
                runOnMonday: false,
                runOnTuesday: false,
                runOnWednesday: false,
                runOnThursday: false,
                runOnFriday: false,
                runOnSaturday: false,
                runOnSunday: false,
                runFromDayInMonth: false,
                runToDayInMonth: false,
                runFromHours: false,
                runToHours: false,
                runEveryXXXMinutes: false
            }
        };
    }

    componentDidMount() {
        const { fetchScheduledJobs } = this.props;
        const { paging, filters } = this.state;

        fetchScheduledJobs(paging, filters);
    }

    componentWillUnmount() {
        const { resetSchduledJob } = this.props;
        resetSchduledJob();
    }

    applyFilters = () => {
        const { fetchScheduledJobs } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchScheduledJobs(newPagination, filters);

        if(this.capriSchduledJobListRef !== null && this.capriSchduledJobListRef !== undefined &&
            this.capriSchduledJobListRef.current !== null && this.capriSchduledJobListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as CapriScheduledJobFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchScheduledJobs } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'procedureName',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
        } as CapriScheduledJobFilter;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchScheduledJobs(newPagination, newFilters);

        if(this.capriSchduledJobListRef !== null && this.capriSchduledJobListRef !== undefined &&
            this.capriSchduledJobListRef.current !== null && this.capriSchduledJobListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchScheduledJobs } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchScheduledJobs(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoading, scheduledJobs, fetchScheduledJobs } = this.props;
        const { filters, menuItem, paging, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <CapriScheduledJobFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.CAPRI_SCHEDULED_JOB}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <CapriScheduledJobList
                                    rows={scheduledJobs} isLoading={isLoading}
                                    ref={this.capriSchduledJobListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchScheduledJobs(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CapriSchduledJobComponent));