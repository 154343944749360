import React from 'react';
import { format } from 'date-fns';
interface INullableFormattedDateProps {
    value?: Date | undefined;
    withTime?: boolean
}
const defaultProps: INullableFormattedDateProps = {
    withTime: false
};

function NullableFormattedDate(props: INullableFormattedDateProps): JSX.Element {
    const { value, withTime } = props;
    let utc_val;
    if(value != null) {
        try {
            // const hour = value.getHours();
            // const utcHour = value.getUTCHours();
            // // eslint-disable-next-line no-console
            // console.log(hour + ':' + utcHour);

            utc_val = Date.UTC(value?.getUTCFullYear(), value?.getUTCMonth(), value?.getUTCDate(), value?.getUTCHours(), value?.getUTCMinutes(), value?.getUTCSeconds());
        }
        catch {
            const valueDate = new Date(value);
            utc_val = Date.UTC(valueDate?.getUTCFullYear(), valueDate?.getUTCMonth(), valueDate?.getUTCDate(), value?.getUTCHours(), value?.getUTCMinutes(), value?.getUTCSeconds());
        }
    }

    return (
        <>
            {utc_val
                ? withTime === true ? < span >{format(utc_val, 'dd/MM/yyyy HH:mm:ss')}</span> : < span >{format(utc_val, 'dd/MM/yyyy')}</span>
                : ' - '
            }
        </>
    );

}

NullableFormattedDate.defaultPros = defaultProps;

export default NullableFormattedDate;