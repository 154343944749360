import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PrintAgreementVersion from '../interfaces/output/printAgreemenVersion';
import PrintAgreementVersionPeriod from '../interfaces/output/printAgreemenVersionPeriod';
import PrintAgreementVersionPeriodLine from '../interfaces/output/printAgreemenVersionPeriodLine';
import PrintAgreementVersionPeriodFilters from '../interfaces/filters/printAgreementVersionPeriodFilters';
import PrintAgreementVersionPeriodLineFilters from '../interfaces/filters/printAgreementVersionPeriodLineFilters';

export type PrintAgreementVersionState = {
    error: HttpErrorResponse | undefined;
    isLoadingPrintAgreementVersion: boolean;
    printAgreementVersion: PrintAgreementVersion;
    isLoadingPrintAgreementVersionPeriod: boolean;
    printAgreementVersionPeriod: Pagination<PrintAgreementVersionPeriod>
    printAgreementVersionPeriodLines: Pagination<PrintAgreementVersionPeriodLine>
    isExportingPrintAgreementPeriodLines: boolean;
    isExportingPrintAgreementPeriodLinesCSV: boolean;
    isRuningPrintAgreementVersion: boolean,
    isLoadingPrintAgreementVersionPeriodLine: boolean
    isLoadingPrintAgreementVersionPeriodById: boolean;
    printAgreementVersionPeriodById: PrintAgreementVersionPeriod,
    isSendingPrintAgreementPeriodLines: boolean;
};

const initialState: PrintAgreementVersionState = {
    error: undefined,
    isLoadingPrintAgreementVersion: false,
    printAgreementVersion: {} as PrintAgreementVersion,
    isLoadingPrintAgreementVersionPeriod: false,
    printAgreementVersionPeriod: { content: [] as Array<PrintAgreementVersionPeriod> } as Pagination<PrintAgreementVersionPeriod>,
    printAgreementVersionPeriodLines: { content: [] as Array<PrintAgreementVersionPeriodLine> } as Pagination<PrintAgreementVersionPeriodLine>,
    isExportingPrintAgreementPeriodLines: false,
    isExportingPrintAgreementPeriodLinesCSV: false,
    isRuningPrintAgreementVersion: false,
    isLoadingPrintAgreementVersionPeriodLine: false,
    isLoadingPrintAgreementVersionPeriodById: false,
    printAgreementVersionPeriodById: {} as PrintAgreementVersionPeriod,
    isSendingPrintAgreementPeriodLines: false

};

export const printAgreementVersionsSlice = createSlice({
    name: 'printAgreementVersion',

    initialState,
    reducers: {
        fetchPrintAgreementVersion: (state: PrintAgreementVersionState, _action: PayloadAction<number>) => {
            state.isLoadingPrintAgreementVersion = true;
        },
        fetchPrintAgreementVersionSuccess: (state: PrintAgreementVersionState, action: PayloadAction<PrintAgreementVersion>) => {
            state.isLoadingPrintAgreementVersion = false;
            state.printAgreementVersion = action.payload;
        },
        fetchPrintAgreementVersionError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPrintAgreementVersion = false;
            state.error = action.payload;
        },
        resetPrintAgreementVersion: (state: PrintAgreementVersionState) => {
            state.printAgreementVersion = initialState.printAgreementVersion;
        },
        fetchPrintAgreementVersionPeriodById: (state: PrintAgreementVersionState, _action: PayloadAction<number>) => {
            state.isLoadingPrintAgreementVersionPeriodById = true;
        },
        fetchPrintAgreementVersionPeriodByIdSuccess: (state: PrintAgreementVersionState, action: PayloadAction<PrintAgreementVersionPeriod>) => {
            state.isLoadingPrintAgreementVersionPeriodById = false;
            state.printAgreementVersionPeriodById = action.payload;
        },
        fetchPrintAgreementVersionPeriodByIdError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPrintAgreementVersionPeriodById = false;
            state.error = action.payload;
        },
        resetPrintAgreementVersionPeriodById: (state: PrintAgreementVersionState) => {
            state.printAgreementVersionPeriodById = initialState.printAgreementVersionPeriodById;
        },
        fetchPrintAgreementVersionPeriod: (state: PrintAgreementVersionState, _action: PayloadAction<{ paging: Paging, filters?: PrintAgreementVersionPeriodFilters }>) => {
            state.isLoadingPrintAgreementVersionPeriod = true;
        },
        fetchPrintAgreementVersionPeriodSuccess: (state: PrintAgreementVersionState, action: PayloadAction<Pagination<PrintAgreementVersionPeriod>>) => {
            state.isLoadingPrintAgreementVersionPeriod = false;
            state.printAgreementVersionPeriod = action.payload;
        },
        fetchPrintAgreementVersionPeriodError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPrintAgreementVersionPeriod = false;
            state.error = action.payload;
        },
        resetPrintAgreementVersionPeriod: (state: PrintAgreementVersionState) => {
            state.printAgreementVersionPeriod = initialState.printAgreementVersionPeriod;
        },
        runProcedurePrintAgreementVersion: (state: PrintAgreementVersionState, _action: PayloadAction<number>) => {
            state.isRuningPrintAgreementVersion = true;
        },
        runProcedurePrintAgreementVersionSuccess: (state: PrintAgreementVersionState, action: PayloadAction<PrintAgreementVersion>) => {
            state.isRuningPrintAgreementVersion = false;
            state.printAgreementVersion = action.payload;
        },
        runProcedurePrintAgreementVersionError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isRuningPrintAgreementVersion = false;
            state.error = action.payload;
        },
        resetRunProcedurePrintAgreementVersion: (state: PrintAgreementVersionState) => {
            state.printAgreementVersion = initialState.printAgreementVersion;
        },
        exportPrintAgreementVersionPeriodLines: (state: PrintAgreementVersionState, _action: PayloadAction<{ filters?: PrintAgreementVersionPeriodLineFilters }>) => {
            state.isExportingPrintAgreementPeriodLines = true;
        },
        exportPrintAgreementVersionPeriodLinesSuccess: (state: PrintAgreementVersionState) => {
            state.isExportingPrintAgreementPeriodLines = false;
        },
        exportPrintAgreementVersionPeriodLinesError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportingPrintAgreementPeriodLines = false;
            state.error = action.payload;
        },
        sendPrintAgreementVersionPeriodLines: (state: PrintAgreementVersionState, _action: PayloadAction<{emailAddress: string|undefined, filters?: PrintAgreementVersionPeriodLineFilters }>) => {
            state.isSendingPrintAgreementPeriodLines = true;
        },
        sendPrintAgreementVersionPeriodLinesSuccess: (state: PrintAgreementVersionState,action: PayloadAction<PrintAgreementVersionPeriodLine>) => {
            state.isSendingPrintAgreementPeriodLines = false;
        },
        sendPrintAgreementVersionPeriodLinesError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isSendingPrintAgreementPeriodLines = false;
            state.error = action.payload;
        },
        exportPrintAgreementVersionPeriodLinesCSV: (state: PrintAgreementVersionState, _action: PayloadAction<{ filters?: PrintAgreementVersionPeriodLineFilters }>) => {
            state.isExportingPrintAgreementPeriodLinesCSV = true;
        },
        exportPrintAgreementVersionPeriodLinesCSVSuccess: (state: PrintAgreementVersionState) => {
            state.isExportingPrintAgreementPeriodLinesCSV = false;
        },
        exportPrintAgreementVersionPeriodLinesCSVError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportingPrintAgreementPeriodLinesCSV = false;
            state.error = action.payload;
        },
        fetchPrintAgreementVersionPeriodLines: (state: PrintAgreementVersionState, _action: PayloadAction<{ paging: Paging, filters?: PrintAgreementVersionPeriodLineFilters }>) => {
            state.isLoadingPrintAgreementVersionPeriodLine = true;
        },
        fetchPrintAgreementVersionPeriodLinesSuccess: (state: PrintAgreementVersionState, action: PayloadAction<Pagination<PrintAgreementVersionPeriodLine>>) => {
            state.isLoadingPrintAgreementVersionPeriodLine = false;
            state.printAgreementVersionPeriodLines = action.payload;
        },
        fetchPrintAgreementVersionPeriodLinesError: (state: PrintAgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPrintAgreementVersionPeriodLine = false;
            state.error = action.payload;
        },
        resetPrintAgreementVersionPeriodLines: (state: PrintAgreementVersionState) => {
            state.printAgreementVersionPeriod = initialState.printAgreementVersionPeriod;
        }
    }
});

export const { fetchPrintAgreementVersion, fetchPrintAgreementVersionError,
    fetchPrintAgreementVersionPeriod, fetchPrintAgreementVersionPeriodError, fetchPrintAgreementVersionPeriodSuccess, fetchPrintAgreementVersionSuccess,
    sendPrintAgreementVersionPeriodLinesSuccess, sendPrintAgreementVersionPeriodLinesError, sendPrintAgreementVersionPeriodLines,
    exportPrintAgreementVersionPeriodLinesCSV, exportPrintAgreementVersionPeriodLinesCSVError, exportPrintAgreementVersionPeriodLinesCSVSuccess,
    runProcedurePrintAgreementVersion, runProcedurePrintAgreementVersionError, runProcedurePrintAgreementVersionSuccess, resetPrintAgreementVersion,
    resetPrintAgreementVersionPeriod, fetchPrintAgreementVersionPeriodLines, fetchPrintAgreementVersionPeriodLinesError, fetchPrintAgreementVersionPeriodLinesSuccess,
    resetPrintAgreementVersionPeriodLines, fetchPrintAgreementVersionPeriodById, fetchPrintAgreementVersionPeriodByIdError, fetchPrintAgreementVersionPeriodByIdSuccess,
    resetPrintAgreementVersionPeriodById, exportPrintAgreementVersionPeriodLines, exportPrintAgreementVersionPeriodLinesError, exportPrintAgreementVersionPeriodLinesSuccess }
    = printAgreementVersionsSlice.actions;

export const isLoadingPrintAgreementVersion = (state: RootState): boolean => state.printAgreementVersionReducer.isLoadingPrintAgreementVersion;
export const isLoadingPrintAgreementVersionPeriod = (state: RootState): boolean => state.printAgreementVersionReducer.isLoadingPrintAgreementVersionPeriod;
export const getPrintAgreementVersion = (state: RootState): PrintAgreementVersion => state.printAgreementVersionReducer.printAgreementVersion;
export const getPrintAgreementVersionPeriod = (state: RootState): Pagination<PrintAgreementVersionPeriod> => state.printAgreementVersionReducer.printAgreementVersionPeriod;
export const isExportingPrintAgreementPeriodLines = (state: RootState): boolean => state.printAgreementVersionReducer.isExportingPrintAgreementPeriodLines;
export const isRuningPrintAgreementVersion = (state: RootState): boolean => state.printAgreementVersionReducer.isRuningPrintAgreementVersion;
export const isLoadingPrintAgreementVersionPeriodLines = (state: RootState): boolean => state.printAgreementVersionReducer.isLoadingPrintAgreementVersionPeriodLine;
export const getPrintAgreementVersionPeriodLines = (state: RootState): Pagination<PrintAgreementVersionPeriodLine> => state.printAgreementVersionReducer.printAgreementVersionPeriodLines;
export const isLoadingPrintAgreementVersionPeriodById = (state: RootState): boolean => state.printAgreementVersionReducer.isLoadingPrintAgreementVersionPeriodById;
export const getPrintAgreementVersionPeriodById = (state: RootState): PrintAgreementVersionPeriod => state.printAgreementVersionReducer.printAgreementVersionPeriodById;
export default printAgreementVersionsSlice.reducer;