import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetScheduledJob } from '../../../reducers/scheduledJobReducer';
import CapriScheduledJobForm from './capriScheduledJobForm';

interface IAddCapriScheduledJobProps {
    classes: any;
    theme: Theme;
    match: any;
    users: any;
    steps: Array<number>;
    capriScheduledJobId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetScheduledJob: () => dispatch(resetScheduledJob())
});

const mapStoreToProps = (store: RootState, ownProps: IAddCapriScheduledJobProps) => {
    return {
        capriScheduledJobId: ownProps.match.params?.capriScheduledJobId ? Number(ownProps.match.params?.capriScheduledJobId) : undefined
    };
};

class AddCapriScheduledJob extends Component<IAddCapriScheduledJobProps> {
    componentWillUnmount() {
        resetScheduledJob();
    }

    render() {
        const { classes, steps, capriScheduledJobId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <CapriScheduledJobForm capriScheduledJobId={capriScheduledJobId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddCapriScheduledJob));