import { List } from 'immutable';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';
import CapriScheduledJob from '../../../../interfaces/output/capriScheduledJob';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    wrongFormat: LanguageUtils.createMessage('Wrong time format'),
    outOfRange: LanguageUtils.createMessage('Value out of range'),
    invalidDays: LanguageUtils.createMessage('Invalid day range')
};

export default class CapriScheduledJobStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateRunFromTo(capriScheduledJob?: CapriScheduledJob | undefined): boolean {
        const runFrom = capriScheduledJob?.runFromDayInMonth;
        const runTo = capriScheduledJob?.runToDayInMonth;

        return this.validateStrictNumberRangeStart(runFrom, runTo);

    }

    static validClock(inputClock: string | undefined): boolean {
        return this.validateInputStringClock(inputClock);
    }

    static validateUserForm(scheduledJob: CapriScheduledJob): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { procedureName,
            runFromHours,
            runToHours
        } = scheduledJob;

        const requiredFields =
            this.validateInputString(procedureName);

        const runToDay = scheduledJob?.runToDayInMonth;
        const validDays = runToDay ? CapriScheduledJobStep1Validation.validateRunFromTo(scheduledJob) : true;

        const runToDaysInMonth =
            scheduledJob.runToDayInMonth ? CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runToDayInMonth, 1, 31) : true;

        const runFromDaysInMonth =
            scheduledJob.runFromDayInMonth ? CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runFromDayInMonth, 1, 31) : true;

        const runEveryMinutes =
            scheduledJob.runFromDayInMonth ? CapriScheduledJobStep1Validation.numberIsInInterval(scheduledJob.runEveryXXXMinutes, 0, 1440) : true;

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        if(!runFromDaysInMonth || !runEveryMinutes || !runToDaysInMonth) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.outOfRange);
        }

        if(!validDays) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDays);
        }

        const acceptedFormatFromHours = runFromHours ? CapriScheduledJobStep1Validation.validClock(runFromHours) : true;

        const acceptedFormatToHours = runToHours ? CapriScheduledJobStep1Validation.validClock(runToHours) : true;

        if(!acceptedFormatFromHours || !acceptedFormatToHours) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.wrongFormat);
        }

        return validationModel;
    }
}
