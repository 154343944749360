import React from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import ActionRenderer from '../common/actionRenderer';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import GenericTextField from '../common/widgets/genericTextField';
import CapriScheduledJobLogFilter from '../../interfaces/filters/capriScheduledJobLogFilter';

interface IFilterProps {
    filters: CapriScheduledJobLogFilter;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    procedureStarted: LanguageUtils.createMessage('Procedure started'),
    procedureEnded: LanguageUtils.createMessage('Procedure ended'),
    errorMessage: LanguageUtils.createMessage('Error message'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CapriScheduledJobLogFilterComponent(props: IFilterProps): JSX.Element {
    const { onChange, applyFilters, setDefaultState, filters } = props;

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <GenericDatePicker
                            name="procedureStarted"
                            label={messages.procedureStarted}
                            value={filters?.procedureStarted}
                            onChange={onChange}
                            type="start"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="procedureEnded"
                            label={messages.procedureEnded}
                            value={filters?.procedureEnded}
                            onChange={onChange}
                            type="end"
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="errorMessage"
                                label={messages.errorMessage}
                                onChange={onChange}
                                value={filters.errorMessage}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}