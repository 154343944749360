import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import CapriScheduledJob from '../../../interfaces/output/capriScheduledJob';
import { getScheduledJob, isCreating, isUpdating, isLoadingById, fetchCapriScheduledJobById, createCapriScheduledJob, updateCapriScheduledJob } from '../../../reducers/scheduledJobReducer';
import CapriScheduledJobFormStep1 from './capriScheduledJobFormStep1';
import CapriScheduledJobStep1Validation from './validations/capriScheduledJobStep1Validation';

interface IUserFormProps {
    capriScheduledJobId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit scheduled job'),
    add: LanguageUtils.createMessage('Add scheduled job')
};

export default function CapriScheduledJobForm(props: IUserFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { capriScheduledJobId, steps, type } = props;
    const [capriScheduledJob, setCapriScheduledJob] = React.useState<CapriScheduledJob>({ id: capriScheduledJobId } as CapriScheduledJob);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsCapriScheduledJob = useSelector(getScheduledJob);
    const propsIsUpdatingScheduledJob = useSelector(isUpdating);
    const prevIsUpdatingScheduledJob = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingScheduledJob);
    const propsIsCreatingScheduledJob = useSelector(isCreating);
    const prevIsCreatingScheduledJob = WorkflowUtils.usePrevious<boolean>(propsIsCreatingScheduledJob);
    const propsIsLoadingScheduledJob = useSelector(isLoadingById);
    const prevIsLoadingScheduledJob = WorkflowUtils.usePrevious<boolean>(propsIsLoadingScheduledJob);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingScheduledJob === true && !propsIsUpdatingScheduledJob) || (prevIsCreatingScheduledJob && !propsIsCreatingScheduledJob)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingScheduledJob, propsIsCreatingScheduledJob]);

    useEffect(() => {
        if(capriScheduledJobId) {
            dispatch(fetchCapriScheduledJobById(capriScheduledJobId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingScheduledJob && !propsIsLoadingScheduledJob) {
            setCapriScheduledJob(propsCapriScheduledJob);
        }

    }, [propsIsLoadingScheduledJob]);

    const onChange = (attribute: string, value: any) => {
        const newScheduledJob = { ...capriScheduledJob } as CapriScheduledJob;
        (newScheduledJob as any)[attribute] = value;

        setCapriScheduledJob(newScheduledJob);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <CapriScheduledJobFormStep1 onChange={onChange} scheduledJob={capriScheduledJob} />,
            validationFn: () => CapriScheduledJobStep1Validation.validateUserForm(capriScheduledJob),
            onNext: () => {
                if(!capriScheduledJob.id) {
                    dispatch(createCapriScheduledJob(capriScheduledJob));
                }
                else {
                    dispatch(updateCapriScheduledJob(capriScheduledJob));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.CAPRI_SCHEDULED_JOB}`;

            case FormType.Edit:
                return `/${UrlConstants.CAPRI_SCHEDULED_JOB}/${capriScheduledJob.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
