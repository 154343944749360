import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import OrderUnit from '../../interfaces/output/orderUnit';
import { isLoadingOrderUnit, isUpdatingOrderUnit, isCreatingUnits } from '../../reducers/orderUnitReducer';
import { fetchFactorSetDepartureTypes, getFactorSetDepartureTypes } from '../../reducers/factorSetDepartureReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../reducers/unitTypeReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../reducers/unitGroupReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../reducers/unitSubgroupReducer';
import { ensure } from '../../utils/arrayUtils';
import { fetchCancellationTypes, getCancellationTypes, isLoadingCancellationTypes } from '../../reducers/cancellationTypeReducer';
import { fetchImportExportCodes, getImportExportCodes, isLoadingImportExportCodes } from '../../reducers/importExportCodeReducer';
import { fetchDangerousGoodsGroups, getDangerousGoodsGroups } from '../../reducers/dangerousGoodsGroupReducer';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import FactorSetType from '../../interfaces/output/factorSetType';
import UnitGroup from '../../interfaces/output/unitGroup';
import UnitSubGroup from '../../interfaces/output/unitSubGroup';
import UnitType from '../../interfaces/output/unitType';
import DangerousGoodsGroup from '../../interfaces/output/dangerousGoodsGroup';
import CancellationType from '../../interfaces/output/cancellationType';
import ImportExportCode from '../../interfaces/output/importExportCode';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericTextField from '../common/widgets/genericTextField';
import { isLoadingDepartureTypes } from '../../reducers/departureTypeReducer';
import OrderUnitStep1Validation from '../orderUnits/validations/orderUnitStep1Validation';
import { fetchPriorityTypes, getPriorityTypes } from '../../reducers/priorityTypeReducer';
import { FormattedMessage } from 'react-intl';

interface AddOrderUnitStep1Props {
    orderUnit: OrderUnit;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    netWeightKg: LanguageUtils.createMessage('Net weight'),
    grossWeightKg: LanguageUtils.createMessage('Gross weight kg'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    departureType: LanguageUtils.createMessage('Departure type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    unitgroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    importExportCode: LanguageUtils.createMessage('Import export code'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    dangerousgoodsgroup: LanguageUtils.createMessage('Dangerous goods group'),
    loadedFlag: LanguageUtils.createMessage('Loaded flag')
};

const ATTRIBUTES = {
    UNIT_NO: 'unitNo',
    DEPARTURE_TYPE: 'departureType',
    PRIORITY_TYPE: 'priorityType',
    GROSS_WEIGHT: 'grossWeightKg',
    UNIT_TYPE: 'unitType',
    UNIT_GROUP: 'unitGroup',
    UNIT_SUBGROUP: 'unitSubGroup',
    CANCELLATION_TYPE: 'cancellationType'
};

const AddOrderStep2 = forwardRef((props: AddOrderUnitStep1Props, ref: any): JSX.Element => {
    const { orderUnit, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const departureTypes = useSelector(getFactorSetDepartureTypes).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    const cancellationTypes = useSelector(getCancellationTypes).content;
    const importExportCodes = useSelector(getImportExportCodes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const dangerousGoodsGroups = useSelector(getDangerousGoodsGroups).content;
    const isLoadingObjects = [useSelector(isCreatingUnits), useSelector(isLoadingOrderUnit), useSelector(isLoadingDepartureTypes),
        useSelector(isLoadingCancellationTypes), useSelector(isLoadingImportExportCodes), useSelector(isUpdatingOrderUnit)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
    const prevOrderUnit = WorkflowUtils.usePrevious<OrderUnit>(orderUnit);

    useEffect(() => {
        dispatch(fetchFactorSetDepartureTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchCancellationTypes({ paging }));
        dispatch(fetchImportExportCodes({ paging }));
        dispatch(fetchDangerousGoodsGroups({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(orderUnit?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(orderUnit.unitType?.unitGroupId),
                paging }));
            if(orderUnit.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(orderUnit.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevOrderUnit) {
            if(orderUnit?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(orderUnit?.unitType?.unitGroupId),
                    paging }));
                if(prevOrderUnit.id === orderUnit.id) {
                    onChange('unitType', { ...orderUnit.unitType,
                        unitSubGroupId: null,
                        id: null });
                    dispatch(resetSubgroups());
                    dispatch(resetUnitTypes());
                }
            }
        }
    }, [orderUnit?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevOrderUnit) {
            if(orderUnit?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(orderUnit?.unitType?.unitSubGroupId),
                    paging }));
                if(prevOrderUnit.id === orderUnit.id) {
                    onChange('unitType', { ...orderUnit.unitType,
                        id: null });
                    dispatch(resetUnitTypes());
                }
            }
        }
    }, [orderUnit?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!orderUnit?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.UNIT_NO: {
                isValid = OrderUnitStep1Validation.validateInputStringLength(orderUnit.unitNo);
                break;
            }
            case ATTRIBUTES.DEPARTURE_TYPE: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.departureType?.id);
                break;
            }
            case ATTRIBUTES.PRIORITY_TYPE: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.priorityType?.id);
                break;
            }
            case ATTRIBUTES.GROSS_WEIGHT: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.grossWeightKg);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.unitType?.id);
                break;
            }
            case ATTRIBUTES.UNIT_GROUP: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.unitType?.unitGroupId);
                break;
            }
            case ATTRIBUTES.UNIT_SUBGROUP: {
                isValid = OrderUnitStep1Validation.validateInputNumber(orderUnit.unitType?.unitSubGroupId);
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="unitNo"
                        label={messages.unitNo}
                        value={orderUnit.unitNo}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.UNIT_NO)}
                        required
                        // helperText={messages.unitNoHelpText}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetType>
                            options={departureTypes}
                            value={orderUnit.departureType}
                            onChange={(obj: FactorSetType | null) => onChange('departureType', obj)}
                            placeholder={messages.departureType}
                            error={setValidationState(ATTRIBUTES.DEPARTURE_TYPE)}
                            required
                            // helperText={messages.invoiceFrequencyHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetType>
                            options={priorityTypes}
                            value={orderUnit.priorityType}
                            onChange={(obj: FactorSetType | null) => onChange('priorityType', obj)}
                            placeholder={messages.priorityType}
                            error={setValidationState(ATTRIBUTES.PRIORITY_TYPE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="netWeightKg"
                        label={messages.netWeightKg}
                        value={orderUnit.netWeightKg}
                        onChange={onChange}
                        inputProps={{ min: 0 }}
                        // helperText={messages.netWeightKgHelpText}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="grossWeightKg"
                        label={messages.grossWeightKg}
                        value={orderUnit.grossWeightKg}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.GROSS_WEIGHT)}
                        required
                        // helperText={messages.grossWeightKgHelpText}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="customerReference"
                        label={messages.customerReference}
                        value={orderUnit.customerReference}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={orderUnit.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === orderUnit.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...orderUnit.unitType,
                                unitGroupId: obj?.id }, 'unitGroupId')}
                            placeholder={messages.unitgroup}
                            error={setValidationState(ATTRIBUTES.UNIT_GROUP)}
                            required
                            // helperText={messages.unitGroupHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={orderUnit.unitType?.unitSubGroupId}
                            compareFn={(o: UnitSubGroup) => o.id === orderUnit.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...orderUnit.unitType,
                                unitSubGroupId: obj?.id }, 'unitSubGroupId')}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            error={setValidationState(ATTRIBUTES.UNIT_SUBGROUP)}
                            required
                            // helperText={messages.unitSubGroupHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={orderUnit.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={orderUnit.unitType?.id}
                            compareFn={(o: UnitType) => o.id === orderUnit.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...orderUnit.unitType,
                                id: obj?.id })}
                            placeholder={messages.unitType}
                            isLoading={orderUnit.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required
                            // helperText={messages.unitTypeyHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<DangerousGoodsGroup>
                            options={dangerousGoodsGroups}
                            value={orderUnit.dangerousGoodsGroup}
                            onChange={(obj: DangerousGoodsGroup | null) => onChange('dangerousGoodsGroup', obj)}
                            placeholder={messages.dangerousgoodsgroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CancellationType>
                            options={cancellationTypes}
                            value={orderUnit.cancellationType}
                            onChange={(obj: CancellationType | null) => onChange('cancellationType', obj)}
                            placeholder={messages.cancellationType}
                            error={setValidationState(ATTRIBUTES.CANCELLATION_TYPE)}
                            // helperText={messages.cancellationTypeHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ImportExportCode>
                            options={importExportCodes}
                            value={orderUnit.importExportCode}
                            onChange={(obj: ImportExportCode | null) => onChange('importExportCode', obj)}
                            placeholder={messages.importExportCode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={orderUnit.loadedFlag}
                                onChange={(e: any, value: boolean) => onChange('loadedFlag', value)}
                            />}
                            label={<FormattedMessage {...messages.loadedFlag} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddOrderStep2;