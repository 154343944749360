import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import CapriScheduledJobLogFilterComponent from './capriScheduledJobLogFilter';
import CapriScheduledJobLogList from './capriScheduledJobLogList';
import CapriScheduledJob from '../../interfaces/output/capriScheduledJob';
import CapriScheduledJobLog from '../../interfaces/output/capriScheduledJobLog';
import CapriScheduledJobLogFilter from '../../interfaces/filters/capriScheduledJobLogFilter';
import {
    fetchCapriScheduledJobById, fetchScheduledJobLogs, isUpdating, getScheduledJob, getScheduledJobLogs,
    isLoadingById, isLoadingScheduledJobLogs, resetScheduledJob,
    deleteCapriScheduledJob,
    isDeleting,
    isDeactivate,
    updateCapriScheduledJob
} from '../../reducers/scheduledJobReducer';
import CapriScheduledJobFilter from '../../interfaces/filters/capriScheduledJobFilter';
import ViewCapriScheduledJob from './viewCapriScheduledJob';
import Loader from '../common/widgets/loader';

interface IServiceDetailsProps {
    classes: any;
    theme: Theme;
    fetchCapriScheduledJobById: any;
    capriScheduledJob: CapriScheduledJob;
    match: any;
    history: any;
    location: any;
    isLoading: boolean;
    isLoadingScheduledJobLogs: boolean;
    capriScheduledJobLogs: Pagination<CapriScheduledJobLog>;
    resetScheduledJob: () => void;
    fetchScheduledJobLogs: (paging: Paging, filters: CapriScheduledJobLogFilter) => void;
    isLoadingById: boolean;
    isDeleting: boolean;
    isDeactivate: boolean;
    updateCapriScheduledJob: any;
    deleteCapriScheduledJob: any;
}

interface IServiceDetailsState {
    capriScheduledJob: CapriScheduledJob;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    filters: CapriScheduledJobLogFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCapriScheduledJobById: (capriSchduledJobId: number) => dispatch(fetchCapriScheduledJobById(capriSchduledJobId)),
    resetScheduledJob: () => dispatch(resetScheduledJob()),
    fetchScheduledJobLogs: (paging: Paging, filters: CapriScheduledJobLogFilter) => dispatch(fetchScheduledJobLogs({
        paging,
        filters
    })),
    updateCapriScheduledJob: (capriSchduledJob: CapriScheduledJob) => dispatch(updateCapriScheduledJob(capriSchduledJob)),
    deleteCapriScheduledJob: (capriSchduledJobId: number) => dispatch(deleteCapriScheduledJob(capriSchduledJobId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Delete'),
    deactivate: LanguageUtils.createMessage('Deactivate'),
    activate: LanguageUtils.createMessage('Activate'),
    subname: LanguageUtils.createMessage('Scheduled job'),
    properties: LanguageUtils.createMessage('Properties'),
    capriScheduledJobLogs: LanguageUtils.createMessage('Scheduled job logs')
};

const mapStoreToProps = (store: RootState) => {
    return {
        capriScheduledJob: getScheduledJob(store),
        isLoadingById: isLoadingById(store),
        capriScheduledJobLogs: getScheduledJobLogs(store),
        isLoadingScheduledJobLogs: isLoadingScheduledJobLogs(store),
        isUpdating: isUpdating(store),
        isDeleting: isDeleting(store),
        isLoading: isLoadingById(store) || isLoadingScheduledJobLogs(store) || isDeleting(store) || isDeactivate(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class CapriScheduledJobDetails extends Component<IServiceDetailsProps, IServiceDetailsState> {

    capriSchduledJobLogListRef: any;
    constructor(props: IServiceDetailsProps) {
        super(props);

        this.state = {
            capriScheduledJob: {} as CapriScheduledJob,
            filters: {} as CapriScheduledJobFilter,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.CAPRI_SCHEDULED_JOB}/${props.match.params.capriScheduledJobId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CAPRI_SCHEDULED_JOB}/${props.match.params.capriScheduledJobId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.capriScheduledJobLogs,
                icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.CAPRI_SCHEDULED_JOB}/${props.match.params.capriScheduledJobId}/logs`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CAPRI_SCHEDULED_JOB}/${props.match.params.capriScheduledJobId}/log`, props.location.pathname)
            }
            ]),
            paging: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: 'procedureStarted',
                sort: 'desc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchCapriScheduledJobById } = this.props;
        const { capriScheduledJobId } = this.props.match.params;

        fetchCapriScheduledJobById(capriScheduledJobId);
    }

    componentDidUpdate(prevProps: IServiceDetailsProps) {
        const { capriScheduledJob, isLoadingById, isDeleting, history } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(capriScheduledJob !== prevProps.capriScheduledJob) {
            this.setState({ capriScheduledJob });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingById && prevProps.isLoadingById)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeleting && prevProps.isDeleting) {
            history.push(`/${UrlConstants.CAPRI_SCHEDULED_JOB}`);
        }
    }

    componentWillUnmount() {
        const { resetScheduledJob } = this.props;
        resetScheduledJob();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchScheduledJobLogs } = this.props;
        const { capriScheduledJobId } = this.props.match.params;
        const { paging, sortModel, filters } = this.state;

        const newPagination = {
            ...paging,
            sort: sortModel
        };

        const newFilter = {
            ...filters,
            capriScheduledJobId: capriScheduledJobId
        } as CapriScheduledJobLogFilter;

        this.setState({
            sortModel: sortModel,
            paging: newPagination
        });

        if(selectedMenuItem?.id === 2) {
            fetchScheduledJobLogs(newPagination, newFilter);
        }
    }

    activateCapriScheduledJob = () => {
        const { capriScheduledJob } = this.state;
        const { updateCapriScheduledJob } = this.props;

        updateCapriScheduledJob({
            ...capriScheduledJob,
            jobEnabled: true
        } as CapriScheduledJob);
    }

    deactivateCapriScheduledJob = () => {
        const { capriScheduledJob } = this.state;
        const { updateCapriScheduledJob } = this.props;

        updateCapriScheduledJob({
            ...capriScheduledJob,
            jobEnabled: false
        } as CapriScheduledJob);
    }

    deleteCapriScheduledJob = () => {
        const { capriScheduledJobId } = this.props.match.params;
        const { deleteCapriScheduledJob } = this.props;

        deleteCapriScheduledJob(capriScheduledJobId);
    }

    getSelectedMenuItem = (state: IServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems, capriScheduledJob } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { capriScheduledJobId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.CAPRI_SCHEDULED_JOB}/${capriScheduledJobId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'handshake-slash',
                        text: messages.deactivate,
                        onClick: this.deactivateCapriScheduledJob,
                        isHidden: !capriScheduledJob.jobEnabled
                    },
                    {
                        icon: 'handshake',
                        text: messages.activate,
                        onClick: this.activateCapriScheduledJob,
                        isHidden: !!capriScheduledJob.jobEnabled
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deleteCapriScheduledJob
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }
    applyFilters = () => {
        const { fetchScheduledJobLogs } = this.props;
        const { paging, filters } = this.state;
        const { capriScheduledJobId } = this.props.match.params;

        const newFilter = {
            ...filters,
            capriScheduledJobId: capriScheduledJobId
        } as CapriScheduledJobLogFilter;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination,
            filters: newFilter
        });

        fetchScheduledJobLogs(newPagination, newFilter);

        if(this.capriSchduledJobLogListRef !== null && this.capriSchduledJobLogListRef !== undefined &&
            this.capriSchduledJobLogListRef.current !== null && this.capriSchduledJobLogListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobLogListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;
        const { capriScheduledJobId } = this.props.match.params;

        const newFilter = { ...filters } as CapriScheduledJobLogFilter;
        (newFilter as any)[attribute] = value;
        newFilter.capriScheduledJobId = capriScheduledJobId;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchScheduledJobLogs } = this.props;
        const { paging } = this.state;
        const { capriScheduledJobId } = this.props.match.params;

        const newSortModel = [{
            field: 'procedureStarted',
            sort: 'desc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
            capriScheduledJobId: capriScheduledJobId
        } as CapriScheduledJobLogFilter;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchScheduledJobLogs(newPagination, newFilters);

        if(this.capriSchduledJobLogListRef !== null && this.capriSchduledJobLogListRef !== undefined &&
            this.capriSchduledJobLogListRef.current !== null && this.capriSchduledJobLogListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.capriSchduledJobLogListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchScheduledJobLogs } = this.props;
        const { sortModel, filters, paging } = this.state;
        const { capriScheduledJobId } = this.props.match.params;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            const newFilters = {
                ...filters,
                capriScheduledJobId: capriScheduledJobId
            } as CapriScheduledJobLogFilter;

            fetchScheduledJobLogs(newPaging, newFilters);

        }
    }

    render() {
        const { classes, isLoadingById, isLoadingScheduledJobLogs, capriScheduledJobLogs, fetchScheduledJobLogs } = this.props;
        const { capriScheduledJob, menuItems, paging, columnVisibilityModel, filters } = this.state;
        const { capriScheduledJobId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingById}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.ProductArena}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: capriScheduledJob.procedureName,
                    icon: <FontAwesomeIcon icon="calendar-alt" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/:capriScheduledJobId/properties`}>
                            <Loader isLoading={isLoadingById}>
                                <ViewCapriScheduledJob capriScheduledJob={capriScheduledJob} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.CAPRI_SCHEDULED_JOB}/:capriScheduledJobId/logs`}>
                            <>
                                <CapriScheduledJobLogFilterComponent
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <CapriScheduledJobLogList
                                    rows={capriScheduledJobLogs} isLoading={isLoadingScheduledJobLogs}
                                    ref={this.capriSchduledJobLogListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        const newFilters = {
                                            ...filters,
                                            capriScheduledJobId: capriScheduledJobId
                                        } as CapriScheduledJobLogFilter;
                                        fetchScheduledJobLogs(newPage, newFilters);
                                        this.setState({
                                            paging: newPage,
                                            filters: newFilters
                                        });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(CapriScheduledJobDetails));