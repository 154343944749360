import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import TableUtils from '../../utils/tableUtils';
import TruncatedStringTooltip from '../common/truncatedStringTooltip';
import DataGridComponent from '../common/dataGrid';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface CapriScheduledJobListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    errorMessage: LanguageUtils.createMessage('Error message'),
    procedureName: LanguageUtils.createMessage('Procedure name'),
    procedureStarted: LanguageUtils.createMessage('Procedure started'),
    procedureEnded: LanguageUtils.createMessage('Procedure ended')

};

const ReportCustomerTermsList = forwardRef((props: CapriScheduledJobListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'procedureName',
                headerName: intl.formatMessage(messages.procedureName),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={applyRowFn(cellValues)?.procedureName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('procedureName', hiddenColumns)
            },
            {
                field: 'procedureStarted',
                headerName: intl.formatMessage(messages.procedureStarted),
                flex: 1,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (params: any) => {
                    return <NullableFormattedDate withTime value={applyRowFn(params)?.procedureStarted} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('procedureStarted', hiddenColumns)
            },
            {
                field: 'procedureEnded',
                headerName: intl.formatMessage(messages.procedureEnded),
                flex: 1,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (params: any) => {
                    return <NullableFormattedDate withTime value={applyRowFn(params)?.procedureEnded} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('procedureEnded', hiddenColumns)
            },
            {
                field: 'errorMessage',
                headerName: intl.formatMessage(messages.errorMessage),
                flex: 2.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.errorMessage} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('errorMessage', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ReportCustomerTermsList;
