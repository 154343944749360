import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CapriScheduledJobFilter from '../interfaces/filters/capriScheduledJobFilter';
import CapriScheduledJob from '../interfaces/output/capriScheduledJob';
import CapriScheduledJobApi from '../api/capriScheduledJobApi';
import {
    createCapriScheduledJobError, createCapriScheduledJobSuccess, deactivateCapriScheduledJobError, deactivateCapriScheduledJobSuccess,
    deleteCapriScheduledJobError, deleteCapriScheduledJobSuccess, fetchCapriScheduledJobByIdError, fetchCapriScheduledJobByIdSuccess,
    fetchScheduledJobLogsError, fetchScheduledJobLogsSuccess, fetchScheduledJobsError, fetchScheduledJobsSuccess,
    updateCapriScheduledJobError,updateCapriScheduledJobSuccess
} from '../reducers/scheduledJobReducer';
import CapriScheduledJobLogFilter from '../interfaces/filters/capriScheduledJobLogFilter';
import CapriScheduledJobLog from '../interfaces/output/capriScheduledJobLog';

function* doFetchScheduledJobs(action: PayloadAction<{ paging: Paging, filters?: CapriScheduledJobFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CapriScheduledJob> = yield call(CapriScheduledJobApi.fetchCapriScheduledJobs, paging, filters);
        yield put(fetchScheduledJobsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchScheduledJobsError(error as HttpErrorResponse));
    }
}

function* doFetchScheduledJobLogs(action: PayloadAction<{ paging: Paging, filters?: CapriScheduledJobLogFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CapriScheduledJobLog> = yield call(CapriScheduledJobApi.fetchCapriScheduledJobLogs, paging, filters);
        yield put(fetchScheduledJobLogsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchScheduledJobLogsError(error as HttpErrorResponse));
    }
}

function* doFetchScheduledJobByIds(action: PayloadAction<string>) {
    const capriSchduledJobId = action.payload;
    
    try {
        const response: CapriScheduledJob = yield call(CapriScheduledJobApi.fetchCapriScheduledJobById, capriSchduledJobId);
        yield put(fetchCapriScheduledJobByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCapriScheduledJobByIdError(error as HttpErrorResponse));
    }
}

function* doCreateScheduledJob(action: PayloadAction<CapriScheduledJob>) {
    const scheduledJob = action.payload;

    try {
        const response: CapriScheduledJob = yield call(CapriScheduledJobApi.createCapriScheduledJob, scheduledJob);

        yield put(createCapriScheduledJobSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCapriScheduledJobError(error as HttpErrorResponse));
    }
}

function* doUpdateScheduledJob(action: PayloadAction<CapriScheduledJob>) {
    const scheduledJob = action.payload;

    try {
        const response: CapriScheduledJob = yield call(CapriScheduledJobApi.updateCapriScheduledJob, scheduledJob);

        yield put(updateCapriScheduledJobSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCapriScheduledJobError(error as HttpErrorResponse));
    }
}

function* doDeleteCapriScheduledJob(action: PayloadAction<number>) {
    const capriSchduledJobId = action.payload;
    
    try {
        const response: CapriScheduledJob = yield call(CapriScheduledJobApi.deleteCapriScheduledJobById, capriSchduledJobId);
        yield put(deleteCapriScheduledJobSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteCapriScheduledJobError(error as HttpErrorResponse));
    }
}

function* doDeactivateCapriScheduledJob(action: PayloadAction<CapriScheduledJob>) {
    const capriSchduledJob = action.payload;
    
    try {
        const response: CapriScheduledJob = yield call(CapriScheduledJobApi.deactivateCapriScheduledJobById, capriSchduledJob);
        yield put(deactivateCapriScheduledJobSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateCapriScheduledJobError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('scheduledJob/fetchScheduledJobs', doFetchScheduledJobs),
    takeLatest('scheduledJob/fetchScheduledJobLogs', doFetchScheduledJobLogs),
    takeLatest('scheduledJob/createCapriScheduledJob', doCreateScheduledJob),
    takeLatest('scheduledJob/updateCapriScheduledJob', doUpdateScheduledJob),
    takeLatest('scheduledJob/deleteCapriScheduledJob', doDeleteCapriScheduledJob),
    takeLatest('scheduledJob/deactivateCapriScheduledJob', doDeactivateCapriScheduledJob),
    takeLatest('scheduledJob/fetchCapriScheduledJobById', doFetchScheduledJobByIds)];

